import React, {FormEvent} from 'react';
import Axios, {AxiosError, AxiosResponse, Method} from 'axios';
import apiConfig from './api-config.json';
import {HeaderComponent} from "./components/HeaderComponent";
import {FooterComponent} from "./components/FooterComponent";
import {
    AddressFormFields,
    AddressFormStructure,
    AppProps,
    CreditFormFields,
    CreditFormStructure,
    DebitFormFields,
    DebitFormStructure,
    EntryProps,
    LanguageSymbol,
    PageComponent,
    PageComponentType,
    PageElementType,
    PaymentMethod,
    PostMessageActions,
    PostMessagePackage,
    SessionData,
} from "./utils/types";
import {ActionComponent} from "./components/ActionComponent";
import {ContentComponent} from "./components/ContentComponent";
import {translations} from "./translations/translations";
import {Spinner} from "./utils/Spinner";
import {globalFindPageElementsByType, handleRedirection, prepareCommonPageComponentProps} from "./utils/utils";
import LocalStorage from "./utils/LocalStorage";

interface AppState {
    sessionData: SessionData | null;
    lang: LanguageSymbol;
    forms: {
        [PageElementType.DEBIT_FORM]: DebitFormStructure | null,
        [PageElementType.ADDRESS_FORM]: AddressFormStructure | null,
        [PageElementType.CREDIT_FORM]: CreditFormStructure | null,
    };
    formsErrors: {
        [PageElementType.DEBIT_FORM]: DebitFormStructure,
        [PageElementType.CREDIT_FORM]: CreditFormStructure,
        [PageElementType.ADDRESS_FORM]: AddressFormStructure
    };
    isLoading: boolean; // waiting  for response or isLoading
    isAppInitialized: boolean; // before first call to backend
}

export class App extends React.Component<EntryProps, AppState> {
    constructor(props: EntryProps) {
        super(props);

        this.state = {
            sessionData: null,
            lang: this.props.lang,
            forms: {
                [PageElementType.DEBIT_FORM]: null,
                [PageElementType.ADDRESS_FORM]: null,
                [PageElementType.CREDIT_FORM]: null
            },
            formsErrors: {
                [PageElementType.DEBIT_FORM]: {
                    [DebitFormFields.OWNER]: '',
                    [DebitFormFields.IBAN]: ''
                },
                [PageElementType.CREDIT_FORM]: {
                    [CreditFormFields.OWNER]: '',
                    [CreditFormFields.CARD_NUMBER]: '',
                    [CreditFormFields.EXP_DATE]: '',
                    [CreditFormFields.CVC]: ''
                },
                [PageElementType.ADDRESS_FORM]: {
                    [AddressFormFields.FIRST_NAME]: '',
                    [AddressFormFields.LAST_NAME]: ''
                }
            },
            isLoading: false,
            isAppInitialized: false
        }

        this.setFormFieldValues = this.setFormFieldValues.bind(this);
        this.setFormFieldErrorMsg = this.setFormFieldErrorMsg.bind(this);
        this.switchLang = this.switchLang.bind(this);
        this.callBackend = this.callBackend.bind(this);
        this.getTranslation = this.getTranslation.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.trimValue = this.trimValue.bind(this);
    }

    componentDidMount() {
        this.setPostMessageCommunication();
        this.handleViewAutoUpdate();
        this.verifyStx();

        // Due to waiting for applying custom font (Roboto), max-height is not correctly calculated
        // So handling onloadingdone is required
        document.fonts.onloadingdone = () => {
            this.forceUpdate();
        }
    }

    render() {
        // Rules: Each component should be wrapped in "row component-X" and "col-12"
        return <div className={`container-fluid ${this.state.isAppInitialized ? "initialized" : "initialization"}`.trim()}>
            {(this.state.isLoading || !this.state.isAppInitialized) ? <Spinner/> : null}
            {this.createPageStructure(this.state.sessionData)}
        </div>;
    }

    private callBackend(path: string,
                        options?: {
                            method?: Method,
                            successCallback?: (res: SessionData) => void,
                            data?: object,
                            errorCallback?: (res: SessionData) => void
                        }) {
        this.setLoading(true);
        Axios.request({
            method: options ? options.method : 'GET',
            url: apiConfig.backendPath + path,
            headers: {
                'X-Contract': this.props.contract,
                'Content-Type': 'application/json',
            },
            data: options ? options.data : {}
        }).then((response: AxiosResponse) => {
            if (options && options.successCallback) {
                options.successCallback(response.data);
            } else {
                // default behaviour when successCallback is not defined
                this.defaultBehaviourAfterCall(response.data);
            }
        }).catch((error: AxiosError) => {
            if (options && options.errorCallback) {
                options.errorCallback(error.response?.data as SessionData)
            } else {
                // default behaviour when errorCallback is not defined
                this.defaultBehaviourAfterCall(error.response?.data as SessionData);
            }
            // Always
        }).then(() => {
        });
    }

    private verifyStx(): void {
        let endpoint = `/backend/smart-transactions/${this.props.stx}/verify`;

        this.callBackend(endpoint, {
            method: 'POST',
            data: {
                server: this.props.server,
                payment_method: this.props.paymentMethod
            },
        });
    }

    // sort and display page structure
    private createPageStructure(sessionData: SessionData | null): JSX.Element[] | null {
        let pageStructure: JSX.Element[] = [];

        if (sessionData && sessionData.page.components.length > 0) {
            sessionData.page.components.sort((a, b) => {
                return a.id - b.id;
            });

            sessionData.page.components.forEach(pageComponent => {
                pageStructure.push(this.createPageComponentByType(pageComponent));
            });
        }

        return pageStructure.length > 0 ? pageStructure : null;
    }

    private createPageComponentByType(pageComponent: PageComponent): JSX.Element {
        let component: { component: JSX.Element, type: PageComponentType };

        switch (pageComponent.type) {
            case PageComponentType.HEADER:
                component = {
                    component: <HeaderComponent {...prepareCommonPageComponentProps(pageComponent, this.prepareAppProps())} />,
                    type: pageComponent.type
                }
                break;
            case PageComponentType.CONTENT:
                component = {
                    component: <ContentComponent {...prepareCommonPageComponentProps(pageComponent, this.prepareAppProps())} />,
                    type: pageComponent.type
                }
                break;
            case PageComponentType.ACTION:
                component = {
                    component: <ActionComponent {...prepareCommonPageComponentProps(pageComponent, this.prepareAppProps())} />,
                    type: pageComponent.type
                }
                break;
            case PageComponentType.FOOTER:
                component = {
                    component: <FooterComponent {...prepareCommonPageComponentProps(pageComponent, this.prepareAppProps())} switchLang={this.switchLang}/>,
                    type: pageComponent.type
                }
                break;
            default:
                console.log(`%cUnknown component type: ${pageComponent.type}`, 'background: #dd0000; color: white; padding: 10px;');
                component = {
                    component: <p className={`element-${pageComponent.type}`}></p>,
                    type: PageComponentType.UNKNOWN
                }
        }

        // By default, each Component is one row and each Element is one col
        // Special case: footer
        switch (component.type) {
            case PageComponentType.FOOTER:
                return <div className={`row component-${component.type}`} key={pageComponent.id}>
                    <div className="col-12">
                        {component.component}
                    </div>
                </div>
            default:
                return <div className={`row component-${component.type}`} key={pageComponent.id}>
                    {component.component}
                </div>
        }
    }

    private switchLang(): void {
        let currentLang = this.state.lang === LanguageSymbol.DE ? LanguageSymbol.EN : LanguageSymbol.DE;

        this.setState({
            lang: currentLang
        })

        LocalStorage.setLang(currentLang);
    }

    private prepareAppProps(): AppProps {
        return {
            server: this.props.server,
            lang: this.state.lang,
            callBackend: this.callBackend,
            transaction: (this.state.sessionData as SessionData).transaction,
            paymentMethod: this.props.paymentMethod as PaymentMethod,
            forms: this.state.forms,
            formsErrors: this.state.formsErrors,
            setFormFieldValues: this.setFormFieldValues,
            setFormFieldErrorMsg: this.setFormFieldErrorMsg,
            isLoading: this.state.isLoading,
            setLoading: this.setLoading,
            getTranslation: this.getTranslation,
            trimValue: this.trimValue
        }
    }

    private setFormFieldValues(values: DebitFormStructure | AddressFormStructure | CreditFormStructure,
                               formType: PageElementType.DEBIT_FORM | PageElementType.ADDRESS_FORM | PageElementType.CREDIT_FORM,
                               callback?: () => void): void {
        this.setState((prevState, prevProps) => {
            return {
                forms: {
                    ...prevState.forms,
                    [formType]: {
                        ...values
                    }
                }
            }
        }, callback)
    }

    private setFormFieldErrorMsg(values: DebitFormStructure | AddressFormStructure | CreditFormStructure,
                               formType: PageElementType.DEBIT_FORM | PageElementType.ADDRESS_FORM | PageElementType.CREDIT_FORM,
                               callback?: () => void): void {
        this.setState((prevState, prevProps) => {
            return {
                formsErrors: {
                    ...prevState.formsErrors,
                    [formType]: {
                        ...values
                    }
                }
            }
        }, callback)
    }

    // translation key can be single, e.g. "buy"
    // or split to sections, e.g. debit.iban (general: PageElementType.key)
    private getTranslation(translation: string): string {
        let key = translation.split('.');

        // @ts-ignore
        // for group.key
        if ((key.length > 1 && translations[this.state.lang][key[0]] && translations[this.state.lang][key[0]][key[1]]) ||
            // @ts-ignore
            // for key
            (key.length === 1 && translations[this.state.lang][key[0]])) {
            // @ts-ignore
            return key.length > 1 ? translations[this.state.lang][key[0]] && translations[this.state.lang][key[0]][key[1]] : translations[this.state.lang][key[0]];
        } else {
            console.warn(`Missing translation ${translation}`);
            return '';
        }
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            isLoading: isLoading
        })
    }

    // default state after call backend
    private setDefaultState(data: SessionData): void {

        this.setState((prevState, prevProps) => {
            return {
                sessionData: data,
                isLoading: false,
                isAppInitialized: true,
            }
        })

        document.body.classList.remove('initialization');
        document.body.classList.add('initialized');
    }

    private defaultBehaviourAfterCall(data: SessionData): void {
        let redirectionElements = globalFindPageElementsByType(data, PageElementType.REDIRECT);

        // for redirection keep spinner, don't display page structure and make redirection
        if (redirectionElements.length > 0) {
            handleRedirection(redirectionElements[0].config, this.state.lang);
        } else {
            this.setDefaultState(data);
            this.parsePageConfig(data);
        }
    }

    private trimValue(event: FormEvent,
                      formType: PageElementType.CREDIT_FORM | PageElementType.DEBIT_FORM | PageElementType.ADDRESS_FORM,
                      callback?: () => void): void {
        let input = event.target as HTMLInputElement;
        let name = input.name as DebitFormFields;
        let form = this.state.forms[formType];


        if (form) {
            this.setFormFieldValues({
                ...form,
                // @ts-ignore
                [name]: form[name].trim()
            }, formType, callback)
        }
    }

    // needed for redirection after 3d secure process (with redirection.html)
    private setPostMessageCommunication(): void {
        window.addEventListener('message', (event: MessageEvent) => {
            let pmObject: PostMessagePackage = event.data;

            switch(pmObject.action) {
                case PostMessageActions.VERIFY:
                    this.verifyStx();
                    break;
            }
        });
    }

    // On resize, we have to rerender view due to handle accordion effect
    // (AccordionElement's max-height have to be recalculated)
    private handleViewAutoUpdate(): void {
        window.addEventListener('resize', (event: Event) => {
            this.forceUpdate();
        });
    }

    private parsePageConfig(sessionData: SessionData | null): void {
        if(sessionData && sessionData.page.config) {
            this.setBackgroundImage(sessionData.page.config.background_image);
            this.setLang(sessionData.page.config.language)
        }
    }

    private setBackgroundImage(url?: string | null): void {
        if(url) {
            document.body.classList.add('custom-background');
            document.body.style.backgroundImage = `url("${url}")`;
        }
    }

    private setLang(lang?: LanguageSymbol | null): void {
        // Set language from backend only if user didn't set it from frontend side
        if(lang && !LocalStorage.getLang()) {
            this.setState({
                lang: lang
            })
        }
    }
}
