import React from "react";
import de from './../assets/images/flag-de.svg';
import en from './../assets/images/flag-en.svg';
import {LanguageSymbol} from "../utils/types";
import {FooterProps} from "../components/FooterComponent";

export class LangSwitcher extends React.Component<FooterProps, {}> {

    render() {
        return <img
            src={this.props.lang === LanguageSymbol.DE ? en : de} alt="Lang EN" className="lang-switcher cursor-pointer"
            onClick={this.props.switchLang}
        />
    }
}
