import {Method} from "axios";
import {FormEvent} from "react";

// naming convention (to avoid name collision with React Components):
// PageComponent, PageElement

export interface EntryProps {
    stx: string | null;
    paymentMethod: PaymentMethod | null;
    contract: string | null;
    server: string | null;
    lang: LanguageSymbol;
}

export enum PaymentMethod {
    SECUCARD = 'secucard',
    DEBIT = 'debit',
    CREDIT_CARD = 'creditcard',
    PAYPAL = 'paypal',
    GOOGLE_PAY = 'googlepay',
    APPLE_PAY = 'applepay',
    INVOICE = 'invoice',
    EASY_CREDIT = 'easycredit',
    EPS = 'eps',
    GIROPAY = 'giropay',
    PAY_IN_ADVANCE = 'prepaid',
    SOFORT = 'sofort',
    AIIA = 'aiia',
    ERROR = 'error'
}

export enum CreditCardTypes {
    MASTERCARD = 'mastercard',
    VISA = 'visa',
    AMEX = 'american express',
    CUP = 'cup',
    JCB = 'jcb',
    DINERS = 'diners',
    DISCOVER = 'discover'
}

export enum UrlParam {
    STX = 'stx',
    CONTRACT = 'contract',
    SERVER = 'server',
    PAYMENT_METHOD = 'payment-method'
}

export enum LanguageSymbol {
    EN = 'en',
    DE = 'de'
}

export enum LocaleSymbol {
    EN_US = 'en-US',
    EN_GB = 'en-GB',
    DE_DE = 'de-DE'
}

export enum Currency {
    EUR = 'EUR'
}

export interface PageConfig {
    background_image?: string | null,
    language?: LanguageSymbol | null
}

export interface SessionData {
    contract?: {},
    merchant?: {},
    transaction: Transaction,
    page: {
        components: PageComponent[],
        name: string,
        config: PageConfig
    },
    error?: {
        message: string
    }
}

export interface Transaction {
    id: string;
    payment_method: PaymentMethod
}

export enum StyleProp {
    MAX_HEIGHT = 'max-height',
    MAX_WIDTH = 'max-width',
    WIDTH = 'width'
}

export interface Config {
    action?: string | null | Label;
    label?: Label;
    icon?: string;
    required?: boolean;
    creditcard_schemes?: CreditCardTypes[] | null,
    options?: {
        [option: string]: Label
    },
    style?: {
        [StyleProp.WIDTH]?: number,
        [StyleProp.MAX_HEIGHT]?: number,
        [StyleProp.MAX_WIDTH]?: number,
        bootstrap?: string[]
    },
    validation?: DynamicFormFieldValidationType | null,
    default?: string | null,
    button_config?: GooglePayButtonConfig | ApplePayButtonConfig
}

export interface GooglePayButtonConfig {
    buttonColor?: google.payments.api.ButtonColor,
    buttonLocale?: LanguageSymbol,
    buttonSizeMode?: google.payments.api.ButtonSizeMode,
    buttonType?: google.payments.api.ButtonType,
    environment: google.payments.api.Environment,
    paymentRequest: google.payments.api.PaymentDataRequest,
    style?: {
        height?: number,
        width?: number
    }
}

export interface ApplePayButtonConfig {
    countryCode?: LanguageSymbol,
    currencyCode?: Currency,
    merchantCapabilities?: string[],
    supportedNetworks?: string[],
    total?: {
        label: string,
        amount: string,
        type: string
    },
    paymentRequest: google.payments.api.PaymentDataRequest,
    style?: {
        height?: number,
        width?: number
    },
    initFunction: {
        url: string,
        data: {
            merchantIdentifier: string,
            displayName: string,
            initiative: string,
            initiativeContext: string
        }
    }
}

export interface ApplePayButtonConfig {

}

// label structure from backend
export interface Label {
    [LanguageSymbol.DE]: string;
    [LanguageSymbol.EN]: string;
    default: string
}

export enum PageElementType {
    HEADLINE = 'headline',
    SUB_HEADLINE = 'sub-headline',
    DEBIT_FORM = 'debit-form',
    CREDIT_FORM = 'credit-form',
    ADDRESS_FORM = 'address-form', // TODO remove (never used)
    DYNAMIC_FORM = 'dynamic-form',
    TEXT = 'text',
    ACTION_BUTTON = 'action-button',
    GOOGLE_PAY_BUTTON = 'google-pay-button',
    APPLE_PAY_BUTTON = 'apple-pay-button',
    LINK = 'link',
    CLOSE_BUTTON = 'close-button',
    REDIRECT_BUTTON = 'redirect-button',
    REDIRECT = 'redirect',
    IFRAME = 'iframe',
    AMOUNT = 'amount',
    TABLE = 'table',
    QR_CODE = 'qr-code',
    PAYMENT_METHOD = 'payment-method',
    IMAGE = 'image',
    ROW = 'row',
    CONTAINER = 'container',
    ACCORDION_MAIN_ELEMENT = 'accordion',
    ACCORDION_SUB_ELEMENT = 'accordion-element', // sub-element of accordion element
    UNKNOWN = 'unknown'
}

// element structure from backend
// For Accordion PageElement in nested Component
export interface PageElement {
    config: Config | null,
    id: number,
    type: PageElementType,
    accordion_elements?: PageElement[],
    elements?: PageElement[],
    form_elements?: DynamicFormFieldElement[];
    row_elements?: PageElement[],
    container_elements?: PageElement[]
}

// TODO
// Unify Component and Element to PageElement
// Because it seems that they are very similar
// And Element can contains other Elements (e.g. accordion-element, row-element, container-element)

// component structure from backend
export interface PageComponent {
    config: Config | null,
    elements: PageElement[],
    id: number,
    type: PageComponentType
}

export interface DynamicFormFieldElement {
    id: number,
    type: DynamicFormFieldType,
    name: string,
    config: Config,
    default: null | string
}

export enum DynamicFormFieldType {
    SEARCH = 'search',
    INPUT = 'input',
    DROPDOWN = 'dropdown',
    SEPARATOR = 'separator',
    ACTION_BUTTON = 'action-button'
}

export enum DynamicFormFieldValidationType {
    PHONE = 'phone',
    EMAIL = 'email'
}

export enum PageComponentType {
    HEADER = 'header',
    ACTION = 'action',
    FOOTER = 'footer',
    UNKNOWN = 'unknown',
    CONTENT = 'content'
}

export enum DebitFormFields {
    OWNER = 'owner',
    IBAN = 'iban',
}

export enum CreditFormFields {
    OWNER = 'owner',
    CARD_NUMBER = 'cardNumber',
    EXP_DATE = 'exp-date',
    CVC = 'cvc'
}

// camel case to fit backend naming
export enum AddressFormFields {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName'
}

// Because DebitForm is a component name
export interface DebitFormStructure {
    [DebitFormFields.OWNER]: string,
    [DebitFormFields.IBAN]: string
}

export interface CreditFormStructure {
    [CreditFormFields.OWNER]: string,
    [CreditFormFields.CARD_NUMBER]: string,
    [CreditFormFields.EXP_DATE]: string,
    [CreditFormFields.CVC]: string,
}

export interface CreditCardEncryptedData {
    masked: {
        card_number: string,
        card_owner: string
    },
    crypted_container: string,
    crypted_skey: string,
    key_filename: string
}

export interface CreditCardType {
    id: string,
    name: string,
    pattern: any,
    valid_length: number[]
}

export interface AddressFormStructure {
    [AddressFormFields.FIRST_NAME]: string,
    [AddressFormFields.LAST_NAME]: string
}

export interface AppProps {
    server: string | null,
    lang: LanguageSymbol,
    callBackend: (path: string, options?: {
        method?: Method,
        data?: object,
        successCallback?: (res: SessionData) => void,
        errorCallback?: (res: SessionData) => void,
    }) => void,
    transaction: Transaction,
    paymentMethod: PaymentMethod,
    forms: {
        [PageElementType.DEBIT_FORM]: DebitFormStructure | null,
        [PageElementType.ADDRESS_FORM]: AddressFormStructure | null,
        [PageElementType.CREDIT_FORM]: CreditFormStructure | null,
    },
    formsErrors: {
        [PageElementType.DEBIT_FORM]: DebitFormStructure,
        [PageElementType.ADDRESS_FORM]: AddressFormStructure,
        [PageElementType.CREDIT_FORM]: CreditFormStructure,
    },
    setFormFieldValues: (values: DebitFormStructure | AddressFormStructure | CreditFormStructure,
                         formType: PageElementType.DEBIT_FORM | PageElementType.ADDRESS_FORM | PageElementType.CREDIT_FORM,
                         callback?: () => void) => void,
    setFormFieldErrorMsg: (values: DebitFormStructure | AddressFormStructure | CreditFormStructure,
                         formType: PageElementType.DEBIT_FORM | PageElementType.ADDRESS_FORM | PageElementType.CREDIT_FORM,
                         callback?: () => void) => void,
    isLoading: boolean,
    setLoading: (state: boolean) => void,
    getTranslation: (key: string) => string,
    trimValue: (event: FormEvent,
                formType: PageElementType.CREDIT_FORM | PageElementType.DEBIT_FORM | PageElementType.ADDRESS_FORM,
                callback?: () => void) => void,
}

// Component is structure that include elements or nested Components (see AccordionElement)
export interface CommonPageComponentProps extends AppProps {
    name: PageComponentType | PageElementType,
    elements?: PageElement[],
    accordion_elements?: PageElement[],
    key: number // for React purposes,
    config: Config | null,
    accordionId?: number,
    toggleVisibilityInAccordion?: (id: number | undefined, state: 'hide' | 'show') => void
}

export interface ExtendedPageComponentsProps {
    // TODO
    // make it optional so then we could remove "fake" functions
    actionButton: ActionButton,
    setActionButtonOptions: (options: {
        onClick?: () => void,
        disabled?: boolean,
        action?: string
    }) => void,
}

export interface PageElementProps extends CommonPageComponentProps, ExtendedPageComponentsProps {
    form_elements?: DynamicFormFieldElement[],
    row_elements?: PageElement[],
    container_elements?: PageElement[],
    id: number
}

export interface AccordionElementProps extends PageElementProps {
    selectedId: number,
    setSelectedId: (id: number) => void,
    id: number
}

export interface ActionButton {
    onClick: (() => void) | null,
    disabled: boolean,
    action: string
}

export interface ValidationRule {
    validator: string,
    errorTranslationKey: string,
    params?: object;
}

export enum FormErrorClasses {
    GENERAL = 'has-errors',
    MSG = 'error-message',
}

export interface PostMessagePackage {
    action: string,
    data?: object
}

export enum PostMessageActions {
    VERIFY = 'verify'
}

export enum InputTypes {
    INSERT_FROM_PASTE = 'insertFromPaste',
    INSERT_TEXT = 'insertText',
    DELETE_CONTENT_BACKWARD = 'deleteContentBackward',
    DELETE_CONTENT_FORWARD = 'deleteContentForward'
}

export enum CcSide {
    FRONT = 'front',
    FRONT_INVALID = 'front-invalid',
    BACK = 'back',
    BACK_INVALID = 'back-invalid'

}
