import React from "react";
import {CommonPageComponentProps, LanguageSymbol, PageElementType, PaymentMethod} from "../utils/types";
import {getIcon, prepareConfigInlineStyles} from "../utils/utils";
import paypal from './../assets/images/payment-methods/paypal.svg';
import sofort from './../assets/images/payment-methods/sofort.svg';
import prepaid from './../assets/images/payment-methods/prepaid.svg';
import invoice from './../assets/images/payment-methods/invoice.svg';
import easycredit from './../assets/images/payment-methods/easycredit.svg';
import debitEN from './../assets/images/payment-methods/debit-en.svg';
import debitDE from './../assets/images/payment-methods/debit-de.svg';


export class PaymentMethodElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <img src={this.getIconByName(getIcon(this.props.config))}
                    alt="logo"
                    className={`element-${PageElementType.PAYMENT_METHOD}`}
                    style={prepareConfigInlineStyles(this.props.config)} />
    }

    getIconByName(iconName: string): string {
        switch (iconName) {
            case PaymentMethod.PAYPAL:
                return paypal;
            case PaymentMethod.SOFORT:
                return sofort;
            case PaymentMethod.PAY_IN_ADVANCE:
                return prepaid;
            case PaymentMethod.INVOICE:
                return invoice;
            case PaymentMethod.DEBIT:
                return this.props.lang === LanguageSymbol.EN ? debitEN : debitDE;
            case PaymentMethod.EASY_CREDIT:
                return easycredit;
            default:
                console.warn('Unrecognized icon name');
                return ''
        }
    }

}

