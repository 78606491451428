import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getConfigBootstrapClasses, getLabelTranslation, prepareConfigInlineStyles} from "../utils/utils";

export class ImageElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <img
            alt="Logo"
            src={getLabelTranslation(this.props.config, this.props.lang)}
            style={prepareConfigInlineStyles(this.props.config)}
            className={`element-${PageElementType.IMAGE} ${getConfigBootstrapClasses(this.props.config)}`.trim()}/>
    }


}

