const creditCardTypes = require('./credit-card-types');

module.exports = (function() {
    var card,
        card_type,
        card_types,
        get_card_type,
        i,
        is_valid_length,
        is_valid_luhn,
        len, normalize,
        ref,
        validate,
        validate_number,
        callback,
        options,
        accepted_cards;

    card_types = creditCardTypes;

    // From documentation on: http://jquerycreditcardvalidator.com:
    // list of accepted credit cards, eg ['visa', 'amex']; if not specified, all supported cards are accepted
    // for instant: cardList = {accept: ['visa', 'mastercard']}

    accepted_cards = function(cardList) {
        options = cardList;
        if (options == null) {
            options = {};
        }
        if (options.accept == null) {
            options.accept = (function() {
                var card_types_index, card_types_length, results;
                results = [];
                for (card_types_index = 0, card_types_length = card_types.length; card_types_index < card_types_length; card_types_index++) {
                    card = card_types[card_types_index];
                    results.push(card.name);
                }
                return results;
            })();
        }
        ref = options.accept;
        for (i = 0, len = ref.length; i < len; i++) {
            card_type = ref[i];
            // eslint-disable-next-line no-loop-func
            if ([].indexOf.call((function() {
                var j, len1, results;
                results = [];
                for (j = 0, len1 = card_types.length; j < len1; j++) {
                    card = card_types[j];
                    results.push(card.name);
                }
                return results;
            })(), card_type) < 0) {
                throw new Error("Credit card type " + card_type + " is not supported");
            }
        }
    };

    get_card_type = function(number) {
        var j, len1, ref1;
        ref1 = (function() {
            var k, len1, ref1, results;
            results = [];
            for (k = 0, len1 = card_types.length; k < len1; k++) {
                card = card_types[k];
                // eslint-disable-next-line no-sequences
                if (ref1 = card.name, [].indexOf.call(options.accept, ref1) >= 0) {
                    results.push(card);
                }
            }
            return results;
        })();
        for (j = 0, len1 = ref1.length; j < len1; j++) {
            card_type = ref1[j];
            if (number.match(card_type.pattern)) {
                return card_type;
            }
        }
        return null;
    };
    is_valid_luhn = function(number) {
        var digit, ccLength, ccIndex, reversedCardNumber, sum;
        sum = 0;
        reversedCardNumber = number.split('').reverse();
        for (ccIndex = 0, ccLength = reversedCardNumber.length; ccIndex < ccLength; ++ccIndex) {
            digit = reversedCardNumber[ccIndex];
            digit = +digit; // the + casts the string to int
            if (ccIndex % 2) {
                digit *= 2;
                if (digit < 10) {
                    sum += digit;
                } else {
                    sum += digit - 9;
                }
            } else {
                sum += digit;
            }
        }
        return sum % 10 === 0;
    };
    is_valid_length = function(number, card_type) {
        return [].indexOf.call(card_type.valid_length, number.length) >= 0;
    };
    validate_number = function(number) {
        var length_valid, luhn_valid;
        card_type = get_card_type(number);
        luhn_valid = false;
        length_valid = false;
        if (card_type != null) {
            luhn_valid = is_valid_luhn(number);
            length_valid = is_valid_length(number, card_type);
        }
        return callback({
            card_type: card_type,
            luhn_valid: luhn_valid,
            length_valid: length_valid
        });
    };
    validate = function(creditCardNumber, callbackFunction, creditCardOptions) {
        var number;
        number = normalize(creditCardNumber);
        callback = callbackFunction;
        accepted_cards(creditCardOptions);
        return validate_number(number);
    };
    normalize = function(number) {
        return number.replace(/[ -]/g, '');
    };

    return {
        validate: validate,
        getCCcompany: get_card_type
    };
})();
