import React from "react";
import {CommonPageComponentProps} from "../utils/types";
import {handleRedirection} from "../utils/utils";

export class RedirectElement extends React.Component<CommonPageComponentProps, {}> {

    componentDidMount() {
        handleRedirection(this.props.config, this.props.lang);
    }

    render() {
        return null;
    }
}
