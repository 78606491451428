import React from "react";
import {CommonPageComponentProps} from "../utils/types";
import {createFakeExtendedComponentProps, createPageComponentStructure} from "../utils/utils";

export class HeaderComponent extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <>
            {createPageComponentStructure(this.props, createFakeExtendedComponentProps())}
        </>
    }
}
