import React from "react";
import {GooglePayButtonConfig, PageElementProps} from "../utils/types";
import {getAction} from "../utils/utils";
import GooglePayButton from "@google-pay/button-react";

export class GooglePayButtonElement extends React.Component<PageElementProps, {}> {

    constructor(props: PageElementProps) {
        super(props);

        this.setAction();

        this.handleClick = this.handleClick.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
    }

    render() {
        const buttonConfig: GooglePayButtonConfig | undefined = this.props.config?.button_config as GooglePayButtonConfig;

        if (buttonConfig === undefined) {
            console.warn('Missing Google Pay config');
            return null;
        }
        return <GooglePayButton
            environment={buttonConfig.environment}
            paymentRequest={{...buttonConfig.paymentRequest}}
            buttonColor={buttonConfig.buttonColor}
            buttonType={buttonConfig.buttonType}
            buttonLocale={this.props.lang}
            style={{...buttonConfig.style}}
            buttonSizeMode="fill"
            onLoadPaymentData={paymentRequest => {
                this.sendRequest(paymentRequest.paymentMethodData.tokenizationData.token);
            }}
            onReadyToPayChange={result => {
                if(!result.isReadyToPay) {
                    this.props.toggleVisibilityInAccordion?.(this.props?.accordionId, 'hide');
                }
            }}
        />
    }

    private handleClick() {
    }

    private setAction() {
        this.props.setActionButtonOptions({action: getAction(this.props.config, this.props.lang)})
    }

    private sendRequest(token: string) {
        let body = this.prepareRequestBody(token);

        if (body) {
            this.props.callBackend(this.props.actionButton.action, {
                method: "POST",
                data: body
            })
        } else {
            console.warn('Bad request body');
        }
    }

    private prepareRequestBody(token: string): object | null {
        let form = JSON.parse(token);

        if (form) {
            return {
                id: this.props.transaction.id,
                server: this.props.server,
                container: {
                    type: "googlepay",
                    private: form
                }
            }
        } else {
            return null;
        }
    }
}

