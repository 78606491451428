/* eslint-disable no-useless-escape */

import {mapCcTypes2Id, removeAllWhiteChars} from "./utils";
import {
    AddressFormStructure,
    AddressFormFields,
    CreditFormStructure,
    CreditFormFields,
    DebitFormStructure,
    DebitFormFields,
    ValidationRule, Config
} from "./types";

const SUPPORTED_CREDIT_CARDS_IDS = require('./../utils/credit-card/supported-credit-cards');
const creditCardValidation = require('./../utils/credit-card/credit-card-validation');

function isEmpty(value: string, ignoreWhitespaces: boolean = true): boolean {
    return (ignoreWhitespaces ? value.replace(/\s/g, '').length : value.length) === 0;
}

function isNotEmpty(value: string, ignoreWhitespaces: boolean = true): boolean {
    return !isEmpty(value, ignoreWhitespaces);
}

function isLongerThan(value: string, length: number = 1): boolean {
    return value.length > length;
}

function hasAtLeast2Letters(value: string): boolean {
    const rule = "[a-zA-Z].*[a-zA-Z]";
    const regex = new RegExp(rule);

    return regex.test(value);
}

function isShorterThan(value: string, length: number = 5): boolean {
    return value.length < length;
}

function hasLength(value: string, params: {length: number}): boolean {
    return value.length === params.length;
}

function isEmail(value: string): boolean {
    // eslint-disable-next-line no-control-regex
    let regex = new RegExp('^([a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$');

    return regex.test(value.toLowerCase());

}

function isPhone(value: string): boolean {
    let regex = new RegExp(/^[\+]?\d{8,16}$/);

    return regex.test(value);
}

// this function check only IBAN syntax without checksum control
function isIbanSyntax(ibanFromInput: string): boolean {
    let iban = removeAllWhiteChars(ibanFromInput);

    if (iban === '') {
        return false;
    } else {
        let IBANcodesWithRegex = {
                'AD': '\d{10}[[:alnum:]]{12}',
                'AE': '\d{21}',
                'AL': '\d{10}[[:alnum:]]{16}',
                'AT': '\d{18}',
                'AZ': '\d{2}[[:alpha:]]{4}[[:alnum:]]{20}',
                'BA': '\d{18}',
                'BE': '\d{14}',
                'BG': '\d{2}[[:alpha:]]{4}\d{6}[[:alnum:]]{8}',
                'BH': '\d{2}[[:alpha:]]{4}[[:alnum:]]{14}',
                'BR': '\d{25}[[:alpha:]][[:alnum:]]',
                'CH': '\d{7}[0-9A-Z]{12}',
                'CR': '\d{19}',
                'CY': '\d{10}[[:alnum:]]{16}',
                'CZ': '\d{22}',
                'DE': '\d{20}',
                'DK': '\d{16}',
                'DO': '\d{2}[[:alnum:]]{4}\d{20}',
                'EE': '\d{18}',
                'ES': '\d{22}',
                'FI': '\d{16}',
                'FR': '\d{12}[[:alnum:]]{11}\d{2}',
                'FO': '\d{16}',
                'GB': '\d{2}[[:alpha:]]{4}\d{14}',
                'GE': '\d{2}[[:alpha:]]{2}\d{16}',
                'GI': '\d{2}[[:alpha:]]{4}[[:alnum:]]{15}',
                'GL': '\d{16}',
                'GR': '\d{9}[[:alnum:]]{16}',
                'GT': '\d{2}[[:alnum:]]{12}[[:alnum:]]{12}',
                'HR': '\d{19}',
                'HU': '\d{26}',
                'IE': '\d{2}[[:alpha:]]{4}\d{14}',
                'IL': '\d{21}',
                'IS': '\d{24}',
                'IT': '\d{2}[[:alpha:]]\d{10}[[:alnum:]]{12}',
                'JO': '\d{2}[[:alpha:]]{4}\d{4}[[:alnum:]]{18}',
                'KW': '\d{2}[[:alpha:]]{4}[[:alnum:]]{22}',
                'KZ': '\d{5}[[:alnum:]]{13}',
                'LB': '\d{6}[[:alnum:]]{20}',
                'LI': '\d{7}[[:alnum:]]{12}',
                'LT': '\d{18}',
                'LU': '\d{5}[[:alnum:]]{13}',
                'LV': '\d{2}[[:alpha:]]{4}[[:alnum:]]{13}',
                'MC': '\d{12}[0-9A-Z]{11}[0-9]{2}',
                'MD': '\d{2}[[:alnum:]]{20}',
                'ME': '\d{20}',
                'MK': '\d{5}[[:alnum:]]{10}\d{2}',
                'MR': '13\d{23}',
                'MT': '\d{2}[[:alpha:]]{4}\d{5}[[:alnum:]]{18}',
                'MU': '\d{2}[[:alpha:]]{4}\d{19}[[:alpha:]]{2}[[:alpha:]]',
                'NL': '\d{2}[[:alpha:]]{4}\d{10}',
                'NO': '\d{13}',
                'PK': '\d{2}[[:alpha:]]{4}[[:alnum:]]{16}',
                'PL': '\d{26}',
                'PS': '\d{2}[[:alpha:]]{4}[[:alnum:]]{21}',
                'PT': '50\d{21}',
                'QA': '\d{2}[[:alpha:]]{4}[[:alnum:]]{21}',
                'RO': '\d{2}[[:alpha:]]{4}[[:alnum:]]{16}',
                'RS': '\d{20}',
                'SA': '\d{4}[[:alnum:]]{18}',
                'SE': '\d{22}',
                'SI': '\d{17}',
                'SK': '\d{22}',
                'SM': '\d{2}[A-Z][0-9]{10}[0-9A-Z]{12}',
                'TN': '59\d{20}',
                'TR': '\d{7}[[:alnum:]]{17}',
                'VG': '\d{2}[[:alpha:]]{4}\d{16}'
            },
            prefix = iban.substring(0, 2).toUpperCase(),
            numbers = iban.substring(2, iban.length),
            regex;
        // @ts-ignore
        if (IBANcodesWithRegex[prefix] === undefined) {
            return false;
        } else {
            // @ts-ignore
            regex = new RegExp(IBANcodesWithRegex[prefix].replace('\d', '\\d'), 'i');
            return numbers.match(regex) !== null;
        }
    }
}

// complex IBAN validation: syntax and control sum
function isIban(ibanFromInput: string): boolean {
    let iban = removeAllWhiteChars(ibanFromInput);

    if (!iban) {
        return false;
    } else if (isIbanSyntax(iban)) {

        let bank: string;
        let checkSumString: string = '';
        let checksum: number;
        let asciiShift: number = 55;

        iban = iban.replace(' ', '');
        bank = iban.substr(4, iban.length - 4) + iban.substr(0, 4); // put first 4 characters at the end

        // convert to integer
        for (let c of bank) {
            let v: number;
            if (c.match(/^[A-Z]$/)) {
                v = c.charCodeAt(0) - asciiShift;
            } else {
                v = +c;
            }
            checkSumString = checkSumString.concat(v.toString());
        }

        // calculate the checksum using modular arithmetic
        checksum = +checkSumString.substr(0, 1);
        for (let i = 1; i < checkSumString.length; i++) {
            let v: number = +checkSumString.substr(i, 1);
            checksum *= 10;
            checksum += v;
            checksum %= 97;
        }

        // IBAN is valid if modulo equals 1
        return checksum === 1;
    } else {
        return false;
    }
}

// function returns true when each property in errorObj has no error key (is empty)
function hasNoErrors(errorObj: any): boolean {
    for (const props in errorObj) {
        // each error property must be empty
        if (errorObj[props]) {
            return false;
        }
    }

    return true;
}

function isCreditCardValid(ccNumber: string): boolean {
    var ccCompany = '';
    creditCardValidation.validate(ccNumber,
        function (result: any) {
            if (result.luhn_valid === true && result.length_valid === true) {
                if (SUPPORTED_CREDIT_CARDS_IDS.indexOf(result.card_type.id) !== -1) {
                    ccCompany = result.card_type.name;
                }
            }
        }
    );
    return ccCompany !== '';
}

// Function returns error translation key (or empty string, if everything is ok)
function checkFieldValidity(form: DebitFormStructure | AddressFormStructure | CreditFormStructure | null,
                            field: DebitFormFields | AddressFormFields | CreditFormFields,
                            rules: ValidationRule[]): string {
    let value;

    if (form) {
        // @ts-ignore
        value = form[field];

        for (let i = 0; i < rules.length; i++) {
            let rule: ValidationRule = rules[i];
            // @ts-ignore
            if (Validator[rule.validator]) {
                // @ts-ignore
                if (!Validator[rule.validator](value, rule.params)) { // [2]
                    return rule.errorTranslationKey;
                }
            } else {
                console.warn('Validator\'s rule function is missing');
                return '';
            }
        }
        return '';
    } else {
        console.warn(`Cannot perform [${field}] validation. Form is null.`)
        return '';
    }
}

function isExpDateValid(expDate: string): boolean {
    let month = expDate.split('/')[0];
    // set to 21 in 2100 :)
    let year = '20' + expDate.split('/')[1];
    let expDateObj = new Date(parseInt(year), parseInt(month));
    let currentDate = new Date();

    return expDateObj.getTime() > currentDate.getTime();
}

// first parameter is always field value (see [2])
function isCompanyIdSupported(ccNumber: string, params: {config: Config | null, id: string}) {
    return mapCcTypes2Id(params.config).includes(params.id);
}

const Validator = {
    isEmpty,
    isNotEmpty,
    isLongerThan,
    isShorterThan,
    hasLength,
    hasAtLeast2Letters,
    isIban,
    hasNoErrors,
    checkFieldValidity,
    isCreditCardValid,
    isExpDateValid,
    isCompanyIdSupported,
    isEmail,
    isPhone
}

export default Validator
