import React from "react";

// HINT: To proper display dimensions (avoid 0 0), each svg file has to include width, height and viewBox (e.g. viewBox = "0 0 width height"
import {ReactComponent as GooglePayLogo} from '../assets/images/payment-methods/google-pay.svg';
import {ReactComponent as ApplePayLogo} from '../assets/images/payment-methods/apple-pay.svg';
import {ReactComponent as PayPalLogo} from '../assets/images/payment-methods/paypal.svg';
import {ReactComponent as PrepaidLogo} from '../assets/images/payment-methods/prepaid.svg';
import {ReactComponent as DebitDeLogo} from '../assets/images/payment-methods/debit-de.svg';
import {ReactComponent as DebitEnLogo} from '../assets/images/payment-methods/debit-en.svg';
import {ReactComponent as SofortLogo} from '../assets/images/payment-methods/sofort.svg';
import {ReactComponent as InvoiceLogo} from '../assets/images/payment-methods/invoice.svg';
import {ReactComponent as EasyCreditLogo} from '../assets/images/payment-methods/easycredit.svg';
import {ReactComponent as EpsLogo} from '../assets/images/payment-methods/eps.svg';
import {ReactComponent as GiropayLogo} from '../assets/images/payment-methods/giropay.svg';
import {ReactComponent as AiiaLogo} from '../assets/images/payment-methods/aiia.svg';
import {LanguageSymbol, PaymentMethod} from "../utils/types";

interface CreditCardIconProps {
    paymentMethod: PaymentMethod,
    lang: LanguageSymbol
}

export class PaymentMethodIcon extends React.Component<CreditCardIconProps, {}> {

    render() {
        return <span className={'payment-method-icon ' + this.props.paymentMethod}>
                {this.getPaymentMethodIcon(this.props.paymentMethod, this.props.lang)}
        </span>
    }

    getPaymentMethodIcon(paymentMethod: PaymentMethod, lang: LanguageSymbol): JSX.Element | null {
        switch (paymentMethod) {
            case PaymentMethod.APPLE_PAY:
                return <ApplePayLogo/>
            case PaymentMethod.GOOGLE_PAY:
                return <GooglePayLogo/>
            case PaymentMethod.PAYPAL:
                return <PayPalLogo/>
            case PaymentMethod.SOFORT:
                return <SofortLogo/>
            case PaymentMethod.PAY_IN_ADVANCE:
                return <PrepaidLogo/>
            case PaymentMethod.INVOICE:
                return <InvoiceLogo/>
            case PaymentMethod.DEBIT:
                return lang === LanguageSymbol.EN ? <DebitEnLogo /> : <DebitDeLogo />;
            case PaymentMethod.EASY_CREDIT:
                return <EasyCreditLogo/>
            case PaymentMethod.EPS:
                return <EpsLogo/>
            case PaymentMethod.GIROPAY:
                return <GiropayLogo/>
            case PaymentMethod.AIIA:
                return <AiiaLogo/>
            default:
                console.warn('Unrecognized payment method icon: ' + paymentMethod);
                return null;
        }
    }
}
