import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getLabelTranslation} from "../utils/utils";

export class SubHeadlineElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <div className={`element-${PageElementType.SUB_HEADLINE}`}>{getLabelTranslation(this.props.config, this.props.lang)}</div>
    }
}
