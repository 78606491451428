import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getLabelTranslation, handleRedirection} from "../utils/utils";

export class RedirectButtonElement extends React.Component<CommonPageComponentProps, {}> {

    constructor(props: CommonPageComponentProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        return <button
            className={`btn element-${PageElementType.REDIRECT_BUTTON}`}
            onClick={this.handleClick}>
            {getLabelTranslation(this.props.config, this.props.lang)}
        </button>
    }

    private handleClick() {
        handleRedirection(this.props.config, this.props.lang);
    }
}

