import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {prepareAmountValue} from "../utils/utils";

export class TableElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <div className={`element-${PageElementType.TABLE}`} dangerouslySetInnerHTML={{__html: this.prepareTable()}}>
        </div>
    }

    private prepareTable(): string {
        let amountTable = prepareAmountValue(this.props.config, this.props.lang); // table (as string) with prepared amount
        let customClasses = 'table';
        let preparedTable;

        preparedTable = amountTable.replace('<table>', `<table class="${customClasses}">`);

        return preparedTable
    }
}
