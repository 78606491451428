import React from "react";

export class Spinner extends React.Component<{}, {}> {

    render() {
        return <div className="wrapper-spinner">
            <div className="bg-cover"/>
            <div className="spinner">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" strokeWidth="13" strokeLinecap="round" cx="50" cy="50" r="40"/>
                </svg>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" strokeWidth="13" strokeLinecap="round" cx="50" cy="50" r="40"/>
                </svg>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        fill="none"
                        strokeWidth="13"
                        strokeLinecap="round"
                        cx="50"
                        cy="50"
                        r="40"
                    />
                </svg>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        fill="none"
                        strokeWidth="14"
                        strokeLinecap="round"
                        cx="50"
                        cy="50"
                        r="40"
                    />
                </svg>
            </div>
        </div>
    }
}
