import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getAction} from "../utils/utils";

export class QrCodeElement extends React.Component<CommonPageComponentProps, {}> {

    readonly DEFAULT_HEIGHT = '600px';

    render() {
        return <img
            alt="Transaction QR code"
            src={getAction(this.props.config, this.props.lang)}
            className={`element-${PageElementType.QR_CODE}`}/>
    }
}

