import React from "react";
import logo from "./../assets/images/secupay-logo.svg";
import {LangSwitcher} from "../elements/LangSwitcher";
import {CommonPageComponentProps} from "../utils/types";
import {createFakeExtendedComponentProps, createPageComponentStructure} from "../utils/utils";

export interface FooterProps extends CommonPageComponentProps {
    switchLang: () => void;
}

export class FooterComponent extends React.Component<FooterProps, {}> {

    render() {
        return <>
            <div className="separator"></div>
            <div className="footer-elements">
            <span id="secupay-logo">
                <img src={logo} alt="Secupay logo"/>
            </span>
                <div className="footer-links">
                    {/*currently there will be only links */}
                    {/*workaround for Component that use only CommonPageComponentProps*/}
                    {createPageComponentStructure(this.props, createFakeExtendedComponentProps())}
                </div>
                <LangSwitcher {...this.props} />
            </div>
        </>
    }
}
