import React from "react";
import {PageElementProps, PageElementType} from "../utils/types";
import {getAction, getLabelTranslation} from "../utils/utils";

export class ActionButtonElement extends React.Component<PageElementProps, {}> {

    constructor(props: PageElementProps) {
        super(props);

        this.setAction();

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        return <button
            className={`btn element-${PageElementType.ACTION_BUTTON}`}
            onClick={this.handleClick}
            // if global isLoading, then buttons also will be disabled
            // BUT we should consider how "disabled" value should be handled
            disabled={this.props.isLoading ? true : this.props.actionButton.disabled}>
            {getLabelTranslation(this.props.config, this.props.lang)}
        </button>
    }

    private handleClick() {
        // Rule for onClick event:
        // If props.actionButton.onClick is not null, then we know that other element set this action (e.g. DebitForm)
        // If it is null, execute direct defaultOnClick function from ActionButton action

        if (this.props.actionButton.onClick === null) {
            this.defaultOnClickAction();
        } else {
            this.props.actionButton.onClick();
        }
    }

    private setAction() {
        this.props.setActionButtonOptions({action: getAction(this.props.config, this.props.lang)})
    }

    private defaultOnClickAction() {
        this.props.callBackend(getAction(this.props.config, this.props.lang), {
            method: 'POST',
            data: {
                id: this.props.transaction.id,
                server: this.props.server
            }
        })
    }
}

