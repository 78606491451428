import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getLabelTranslation} from "../utils/utils";

export class TextElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <div className={`element-${PageElementType.TEXT}`} dangerouslySetInnerHTML={{__html: getLabelTranslation(this.props.config, this.props.lang)}} />
    }
}
