import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getAction, getLabelTranslation} from "../utils/utils";

export class LinkElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <a
            className={`element-${PageElementType.LINK}`}
            href={getAction(this.props.config, this.props.lang)}
            target="_blank" rel="noopener noreferrer">
            {getLabelTranslation(this.props.config, this.props.lang)}
        </a>
    }
}
