import React from "react";
import {ApplePayButtonConfig, PageElementProps, PageElementType} from "../utils/types";
import {getAction} from "../utils/utils";

export class ApplePayButtonElement extends React.Component<PageElementProps, {}> {

    buttonConfig: ApplePayButtonConfig | undefined;

    constructor(props: PageElementProps) {
        super(props);

        this.buttonConfig = this.props.config?.button_config as ApplePayButtonConfig;

        let scriptTag = document.createElement('script');
        scriptTag.src = 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js';
        scriptTag.async = true;
        scriptTag.crossOrigin = 'anonymous';

        scriptTag.onload = () => {
            // @ts-ignore
            if (!window.ApplePaySession || !ApplePaySession.canMakePayments()) {
                this.props.toggleVisibilityInAccordion?.(this.props?.accordionId, 'hide');
                }
            }

        document.head.appendChild(scriptTag);

        this.setAction();

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        return <button onClick={this.handleClick} className={`cursor-pointer element-${PageElementType.APPLE_PAY_BUTTON}`}></button>
    }

    private handleClick() {

        // Creating an Apple Pay Session
        // https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/creating_an_apple_pay_session

        // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest
        // @ts-ignore
        const session = new ApplePaySession(1, {
            currencyCode: this.buttonConfig?.currencyCode,
            countryCode: this.buttonConfig?.countryCode,

            // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaylineitem
            total: {
                label: this.buttonConfig?.total?.label,
                amount: this.buttonConfig?.total?.amount,
                type: this.buttonConfig?.total?.type
            },

            // https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrequest/2951831-supportednetworks
            supportedNetworks: this.buttonConfig?.supportedNetworks,


            // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaymerchantcapability
            merchantCapabilities: this.buttonConfig?.merchantCapabilities
        });

        // Providing Merchant Validation
        // https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/providing_merchant_validation
        // https://applepaydemo.apple.com/#merchantValidation
        session.onvalidatemerchant = (event: any) => {
            // Call your own server to request a new merchant session.
            fetch('/backend/apple-pay/init', {
                method: 'POST',
                body: JSON.stringify({
                        merchantIdentifier: this.buttonConfig?.initFunction.data.merchantIdentifier,
                        displayName: this.buttonConfig?.initFunction.data.displayName,
                        initiative: this.buttonConfig?.initFunction.data.initiative,
                        initiativeContext: this.buttonConfig?.initFunction.data.initiativeContext
                    }
                )
            })
                .then(res => res.json()) // Parse response as JSON.
                .then(merchantSession => {
                    session.completeMerchantValidation(merchantSession);
                })
                .catch(err => {
                    console.error("Error fetching merchant session", err);
                });
        };

        // @ts-ignore
        session.onpaymentauthorized = (event: any) => {
            let form = event.payment.token.paymentData;

            this.props.callBackend(this.props.actionButton.action, {
                method: "POST",
                data: {
                    id: this.props.transaction.id,
                    server: this.props.server,
                    container: {
                        type: "applepay",
                        private: form
                    }
                }
            })

            session.completePayment(session.STATUS_SUCCESS);
        }

        // @ts-ignore
        session.oncancel = (event) => {
        }

        session.begin();
    }

    private setAction() {
        this.props.setActionButtonOptions({action: getAction(this.props.config, this.props.lang)})
    }
}
