import React from "react";
import {PageElementProps, PageElementType} from "../utils/types";
import {createPageElementByType, getConfigBootstrapClasses, prepareRwdClass} from "../utils/utils";

export class ContainerElement extends React.Component<PageElementProps, {}> {

    render() {
        return <div className={`element-${PageElementType.CONTAINER} ${prepareRwdClass(this.props.config)} ${getConfigBootstrapClasses(this.props.config)}`.trim()}>
            {this.prepareContainerStructure()}
        </div>
    }

    prepareContainerStructure(): JSX.Element[] | null {
        let containerElements: JSX.Element[] = [];

        if(this.props.container_elements) {
            this.props.container_elements.forEach(el => {
                containerElements.push(createPageElementByType(el, this.props, this.props));
            })
            return containerElements;
        } else {
            return null;
        }
    }
}
