import React, {FormEvent} from "react";
import {
    Config,
    DynamicFormFieldElement,
    DynamicFormFieldType,
    DynamicFormFieldValidationType,
    FormErrorClasses,
    Label,
    PageElementProps,
    PageElementType,
} from "../utils/types";
import {getAction, getLabelTranslation, prepareRwdClass} from "../utils/utils";
import Validator from "../utils/Validator";

enum FormFieldProps {
    NAME = 'name',
    VALUE = 'value',
    ERROR = 'error'
}

interface FormField {
    [FormFieldProps.NAME]: string,
    [FormFieldProps.VALUE]: string,
    [FormFieldProps.ERROR]: string,

    [prop: string]: string
}

interface DynamicFormState {
    fields: FormField[];
}

export class DynamicForm extends React.Component<PageElementProps, DynamicFormState> {

    constructor(props: PageElementProps) {
        super(props);

        this.handleOnInput = this.handleOnInput.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);

        if (this.props.form_elements) {
            this.initializeFormStructure(this.props.form_elements);
        }
    }

    render() {
        return <div className={`row mt-3 mb-3 element-${PageElementType.DYNAMIC_FORM}`}>
            {this.createFormStructure(this.props.form_elements)}
        </div>
    }

    // Can be only used in constructor due to directly change state
    initializeFormStructure(fields: DynamicFormFieldElement[]) {
        let fieldsStructure: FormField[] = [];

        fields.forEach(f => {
            if (f.name) {
                fieldsStructure.push({
                    name: f.name,
                    value: f.config.default || '',
                    error: ''
                });
            }
        })

        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = {
            fields: fieldsStructure
        }
    }

    createFormStructure(fields: DynamicFormFieldElement[] | undefined): JSX.Element[] | null {
        let structure: JSX.Element[] = [];
        let key = 0; // for React purposes

        let tmp: (field: DynamicFormFieldElement, key: number) => JSX.Element = field => {
            let inputElement: JSX.Element = <input className="form-control" type="input" name={field.name} placeholder="Not supported yet" disabled/>;
            return this.createFieldWrapper(field, inputElement, key++);
        }

        if (fields === undefined) {
            return null;
        }

        fields.forEach(field => {
                switch (field.type) {
                    case DynamicFormFieldType.INPUT:
                        structure.push(this.createInput(field, key++));
                        break;
                    case DynamicFormFieldType.DROPDOWN:
                        structure.push(this.createDropdown(field, key++));
                        break;
                    case DynamicFormFieldType.SEARCH:
                        structure.push(this.createSearch(field, key++));
                        break;
                    case DynamicFormFieldType.ACTION_BUTTON:
                        structure.push(this.createActionButton(field, key++));
                        break;
                    default:
                        structure.push(tmp(field, key++))
                }
        })

        return structure;
    }

    updateField(fieldName: string, fieldProp: string, value: string) {
        let fields;
        let fieldIndex;

        fields = this.state.fields;
        fieldIndex = fields.findIndex(f => f.name === fieldName);
        fields[fieldIndex][fieldProp] = value;

        this.setState({
            fields: fields
        })
    }

    handleOnInput(event: FormEvent) {
        let input = event.target as HTMLInputElement;

        this.updateField(input.name, FormFieldProps.ERROR, '');
        this.updateField(input.name, FormFieldProps.VALUE, input.value);
    }

    handleOnChange(event: FormEvent) {
        let input = event.target as HTMLInputElement;

        this.updateField(input.name, FormFieldProps.ERROR, '');
        this.updateField(input.name, FormFieldProps.VALUE, input.value);
    }

    handleOnSubmit() {
        let payload: { [field: string]: string | object } = {};
        const FIELD_SEPARATOR = '.';
        let mergedFields;
        let actionButton: DynamicFormFieldElement | undefined = this.props.form_elements?.find(el => el.type === 'action-button' as DynamicFormFieldType);

        // This code looks stupid, but works ^^
        // (Problem is with calculating and merging nested object without overwriting the same props)
        // 'obj' is passed by reference, so it will be updated
        let setValueAtLevel: (obj: object, path: string[], value?: string) => void = (obj, path, value) => {

            // We can't create inner function or variable because we could get 'undefined' value
            // and then it won't be possible to update original object

            // Set value if is available (but distinguish it between undefined and empty string), otherwise create empty object (if it is not yet created)

            if (path.length === 1) {
                // @ts-ignore
                obj[path[0]] = (value === undefined ? (obj[path[0]] || {}) : value);
            }

            if (path.length === 2) {
                // @ts-ignore
                obj[path[0]][path[1]] = (value === undefined ? (obj[path[0]][path[1]] || {}) : value);
            }

            if (path.length === 3) {
                // @ts-ignore
                obj[path[0]][path[1]][path[2]] = (value === undefined ? (obj[path[0]][path[1]][path[2]] || {}) : value);
            }

            if (path.length === 4) {
                // @ts-ignore
                obj[path[0]][path[1]][path[2]][path[3]] = (value === undefined ? (obj[path[0]][path[1]][path[2]][path[3]] || {}) : value);
            }

            if (path.length === 5) {
                // @ts-ignore
                obj[path[0]][path[1]][path[2]][path[3]][path[4]] = (value === undefined ? (obj[path[0]][path[1]][path[2]][path[3]][path[4]] || {}) : value);
            }

            // in other cases (1 < length > 5)
            if (path.length < 1 || path.length > 5) {
                console.warn('Path out of range');
                return;
            }
        }

        if (this.isFormValid()) {

            mergedFields = this.state.fields.map(f => {
                return {
                    path: f.name.split(FIELD_SEPARATOR),
                    value: f.value
                }
            });

            // prepare payload
            mergedFields.forEach(field => {
                for (let i = 0; i < field.path.length; i++) {
                    // if we reach last element (leaf) then set value
                    if ((i + 1) === field.path.length) {
                        setValueAtLevel(payload, field.path.slice(0, i + 1), field.value);
                    } else {
                        setValueAtLevel(payload, field.path.slice(0, i + 1));
                    }
                }
            })

            if (actionButton) {
                this.props.callBackend(getAction(actionButton.config, this.props.lang), {
                    method: 'POST',
                    data: payload
                })
            }
        }

    }

    isFormValid(): boolean {
        let fieldName: string;
        let isValid: boolean = true;

        if (this.props.form_elements) {

            this.props.form_elements.forEach(element => {
                fieldName = element.name;
                    // Validation: is required (not empty)
                    if (element.config.required && this.getFieldProp(element, FormFieldProps.VALUE) === '') {
                        this.updateField(fieldName, FormFieldProps.ERROR, this.props.getTranslation(`${PageElementType.DYNAMIC_FORM}.invalid_data`));
                        isValid = false;
                    }

                    // Custom validation (only when field is not empty)
                    if (element.config.validation && this.getFieldProp(element, FormFieldProps.VALUE) !== '') {
                        switch (element.config.validation) {
                            case DynamicFormFieldValidationType.EMAIL:
                                // Only when field is not empty
                                if (!Validator.isEmail(this.getFieldProp(element, FormFieldProps.VALUE))) {
                                    this.updateField(fieldName, FormFieldProps.ERROR, this.props.getTranslation(`${PageElementType.DYNAMIC_FORM}.invalid_data`));
                                    isValid = false;
                                }
                                break;
                            case DynamicFormFieldValidationType.PHONE:
                                if (!Validator.isPhone(this.getFieldProp(element, FormFieldProps.VALUE))) {
                                    this.updateField(fieldName, FormFieldProps.ERROR, this.props.getTranslation(`${PageElementType.DYNAMIC_FORM}.invalid_data`));
                                    isValid = false;
                                }
                                break;
                            default:
                                console.warn('Unknown type of validation');
                        }
                    }
            });
        }

        return isValid;
    }

    getFieldProp(field: DynamicFormFieldElement, propName: FormFieldProps): string {
        return this.state.fields.find(f => f.name === field.name)?.[propName] as string;
    }

    createLabel(field: DynamicFormFieldElement): JSX.Element {
        return <label className="form-label">{getLabelTranslation(field.config, this.props.lang)}</label>
    }

    createFieldWrapper(field: DynamicFormFieldElement, htmlElement: JSX.Element | null, key: number): JSX.Element {
        let isSeparator: boolean = field.type === DynamicFormFieldType.SEPARATOR;

        return <div
            className={`form-field-wrapper col-12 col-sm-${prepareRwdClass(field.config)} ${isSeparator ? 'd-none d-sm-block' : ''} ${this.getFieldProp(field, FormFieldProps.ERROR) ? 'has-errors' : ''}`}
            key={key}>
            {field.type !== DynamicFormFieldType.SEPARATOR ? <>
                {this.createLabel(field)}
                {htmlElement}
                {this.getFieldProp(field, FormFieldProps.ERROR) &&
                    <p className={"mt-1 " + FormErrorClasses.MSG}>
                        {this.props.getTranslation(`${PageElementType.DYNAMIC_FORM}.invalid_data`)}
                    </p>}
            </> : null
            }
        </div>
    }

    createInput(field: DynamicFormFieldElement, key: number) {
        let input: JSX.Element = <input
            className="form-control"
            type="text"
            name={field.name}
            value={this.getFieldProp(field, FormFieldProps.VALUE)}
            onInput={event => this.handleOnInput(event)}
        />;

        return this.createFieldWrapper(field, input, key);
    }

    createDropdown(field: DynamicFormFieldElement, key: number, sorted: boolean = false): JSX.Element {
        let prepareOptions: (field: DynamicFormFieldElement) => JSX.Element[] = field => {
            let key = 0;
            // for dropdown first option is always empty
            let preparedOptions: JSX.Element[] = [<option value="" key={key++}></option>];
            let option: Label;

            if (field.config.options) {
                for (const optName in field.config.options) {
                    option = field.config.options[optName];
                    preparedOptions.push(<option value={optName} key={key++}>{getLabelTranslation({label: option} as Config, this.props.lang)}</option>);
                }
            }

            return preparedOptions;
        }

        let dropdown: JSX.Element = <select
            className="form-select"
            name={field.name}
            value={this.getFieldProp(field, FormFieldProps.VALUE)}
            onChange={event => this.handleOnChange(event)}>
            {prepareOptions(field)}
        </select>

        return this.createFieldWrapper(field, dropdown, key++);
    }

    createSearch(field: DynamicFormFieldElement, key: number, sorted: boolean = false): JSX.Element {
        let prepareOptions: (field: DynamicFormFieldElement) => JSX.Element[] = field => {
            let key = 0;
            // for dropdown first option is always empty
            let preparedOptions: JSX.Element[] = [<option value="" key={key++}></option>];
            let optionsSortedByLang: {symbol: string, translations: Label}[] = [];

            if (field.config.options) {

                for (const optName in field.config.options) {
                    optionsSortedByLang.push({symbol: optName, translations: field.config.options[optName]});
                }

                optionsSortedByLang.sort((country1, country2) => {
                    let tr1 = getLabelTranslation({label: country1.translations} as Config, this.props.lang);
                    let tr2 = getLabelTranslation({label: country2.translations} as Config, this.props.lang);
                    return tr1.localeCompare(tr2);
                })

                optionsSortedByLang.forEach(opt => {
                    preparedOptions.push(<option value={opt.symbol} key={key++}>{getLabelTranslation({label: opt.translations} as Config, this.props.lang)}</option>);
                })
            }

            return preparedOptions;
        }


        let dropdown: JSX.Element = <select
            className="form-select"
            name={field.name}
            value={this.getFieldProp(field, FormFieldProps.VALUE)}
            onChange={event => this.handleOnChange(event)}>
            {prepareOptions(field)}
        </select>

        return this.createFieldWrapper(field, dropdown, key++);
    }


    createActionButton(field: DynamicFormFieldElement, key: number): JSX.Element {

        return <div className={`form-field-wrapper col-12 wrapper-${PageElementType.ACTION_BUTTON}`} key={key}>
            <button
                className={`btn element-${PageElementType.ACTION_BUTTON}`}
                onClick={this.handleOnSubmit}
                // TODO Part below was copied from global ActionButton. Check is this is still relevant here
                // if global isLoading, then buttons also will be disabled
                // BUT we should consider how "disabled" value should be handled
                disabled={this.props.isLoading ? true : this.props.actionButton.disabled}>
                {field ? getLabelTranslation(field.config, this.props.lang) : ''}
            </button>
        </div>
    }
}
