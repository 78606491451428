import React from "react";

// HINT: To proper display dimensions (avoid 0 0), each svg file has to include width, height and viewBox (e.g. viewBox = "0 0 width height"
import {ReactComponent as VisaLogo} from './../assets/images/credit-cards/visa.svg';
import {ReactComponent as CreditCardFront} from '../assets/images/credit-cards/credit-card-front.svg';
import {ReactComponent as CreditCardBack} from '../assets/images/credit-cards/credit-card-back.svg';
import {ReactComponent as CreditCardFrontInvalid} from '../assets/images/credit-cards/credit-card-front-invalid.svg';
import {ReactComponent as CreditCardBackInvalid} from '../assets/images/credit-cards/credit-card-back-invalid.svg';
import {ReactComponent as MasterCardLogo} from '../assets/images/credit-cards/mastercard.svg';
import {ReactComponent as CupLogo} from '../assets/images/credit-cards/cup.svg';
import {ReactComponent as DinersClubLogo} from '../assets/images/credit-cards/diners-club.svg';
import {ReactComponent as DiscoverLogo} from '../assets/images/credit-cards/discover.svg';
import {ReactComponent as AmericanExpressLogo} from '../assets/images/credit-cards/american-express.svg';
import {ReactComponent as JcbLogo} from '../assets/images/credit-cards/jcb.svg';
import {CcSide} from "../utils/types";

interface CreditCardIconProps {
    ccCompanyId: string,
    cardSide: CcSide
}

export class CreditCardIcon extends React.Component<CreditCardIconProps, {}> {

    render() {
        return <span className={'cc-icon ' + this.getCcCompanyClass(this.props.ccCompanyId, this.props.cardSide)}>
            {this.getCcIcon(this.props.ccCompanyId, this.props.cardSide)}
        </span>
    }

    getCcIcon(iconId: string, side: CcSide): JSX.Element {
        // order is important!
        // Side different from FRONT has higher priority
        if (side === CcSide.FRONT_INVALID) {
            return <CreditCardFrontInvalid/>
        }

        if (side === CcSide.BACK) {
            return <CreditCardBack/>
        }

        if (side === CcSide.BACK_INVALID) {
            return <CreditCardBackInvalid/>
        }

        switch (iconId) {
            case '1':
                return <AmericanExpressLogo/>
            case '3':
                return <DinersClubLogo/>
            case '4':
                return <JcbLogo/>
            case '7':
                return <VisaLogo/>
            case '8':
                return <MasterCardLogo/>
            case '10':
                return <DiscoverLogo/>
            case '11':
                return <CupLogo/>
            default:
                return <CreditCardFront/>
        }
    }

    getCcCompanyClass(iconId: string, side: CcSide): string {
        let className = '';

        // order inside this function is important
        switch (iconId) {
            case '1':
                className = 'american-express';
                break;
            case '3':
                className = 'diners-club';
                break;
            case '4':
                className = 'jcb';
                break;
            case '7':
                className = 'visa';
                break;
            case '8':
                className = 'master-card';
                break;
            case '10':
                className = 'discover';
                break;
            case '11':
                className = 'cup';
                break;
            default:
                className = 'front';
        }

        // Side different from FRONT has higher priority than iconId
        // So className will be overwritten
        if (side === CcSide.FRONT_INVALID) {
            className = 'front-invalid';
        }

        if (side === CcSide.BACK) {
            className = 'back';
        }

        if (side === CcSide.BACK_INVALID) {
            className = 'back-invalid';
        }

        // recognized Id will have additional cc-company class
        // but only when side is FRONT
        return !isNaN(parseInt(iconId)) && side === CcSide.FRONT ? `cc-company ${className}` : className
    }
}
