import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getLabelTranslation} from "../utils/utils";

export class HeadlineElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <h1 className={`element-${PageElementType.HEADLINE}`}>{getLabelTranslation(this.props.config, this.props.lang)}</h1>
    }
}
