import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/index.scss';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {getEnumValues, getUrlParam} from "./utils/utils";
import {LanguageSymbol, PaymentMethod, UrlParam} from "./utils/types";
import LocalStorage from "./utils/LocalStorage";
import {createRoot} from "react-dom/client";

const container = document.getElementById('pay-secupay-app')!;
const root = createRoot(container);
root.render(init());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function init() {
    return <React.StrictMode>
        <App
            stx={getStx()}
            contract={getContract()}
            server={getServer()}
            lang={getLang()}
            paymentMethod={getPaymentMethod()}/>
    </React.StrictMode>
}

function getStx(): string | null {
    return getUrlParam(UrlParam.STX);
}

function getContract(): string | null {
    return getUrlParam(UrlParam.CONTRACT);
}

function getServer(): string | null {
    return getUrlParam(UrlParam.SERVER);
}

function getLang(): LanguageSymbol {
    return LocalStorage.getLang() || LanguageSymbol.DE;
}

function getPaymentMethod(): PaymentMethod | null {
    let pm = getUrlParam(UrlParam.PAYMENT_METHOD);

    if (pm && getEnumValues(PaymentMethod).includes(pm)) {
        return pm as PaymentMethod;
    } else {
        return null;
    }
}
