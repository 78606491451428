import React from "react";
import {ActionButton, CommonPageComponentProps, ExtendedPageComponentsProps} from "../utils/types";
import {createPageComponentStructure} from "../utils/utils";

interface ContentComponentState {
    actionButton: ActionButton
}

// ContentComponent is component (PageElement) which include other elements
// Each component must have state with action button to not overwrite others
// TIP: accordion-element is nested Component
export class ContentComponent extends React.Component<CommonPageComponentProps, ContentComponentState> {

    constructor(props: CommonPageComponentProps) {
        super(props);

        this.state = {
            actionButton: {
                onClick: null,
                disabled: false,
                action: ''
            },
        }

        this.setActionButtonOptions = this.setActionButtonOptions.bind(this);
    }

    render() {
        // we need this <div> to display App as iframe
        return <div>
            {createPageComponentStructure(this.props, this.prepareExtendedProps(), this.props.name)}
        </div>
    }

    private setActionButtonOptions(options: {
        onClick?: () => void,
        disabled?: boolean,
        action?: string
    }) {
        this.setState((prevState, prevProps) => {
            // avoid concurrency
            return {
                ...prevState,
                actionButton: {
                    ...prevState.actionButton,
                    ...options
                }
            }
        });
    }

    private prepareExtendedProps(): ExtendedPageComponentsProps {
        return {
            actionButton: this.state.actionButton,
            setActionButtonOptions: this.setActionButtonOptions
        }
    }
}
