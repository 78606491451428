import {
    AppProps,
    CommonPageComponentProps,
    Config,
    CreditCardTypes,
    ExtendedPageComponentsProps,
    InputTypes,
    Label,
    LanguageSymbol,
    LocaleSymbol,
    PageComponent,
    PageComponentType,
    PageElement,
    PageElementProps,
    PageElementType,
    SessionData,
    StyleProp
} from "./types";
import {ActionButtonElement} from "../elements/ActionButtonElement";
import {GooglePayButtonElement} from "../elements/GooglePayButtonElement";
import React, {FormEvent} from "react";
import {CloseButtonElement} from "../elements/CloseButtonElement";
import {HeadlineElement} from "../elements/HeadlineElement";
import {SubHeadlineElement} from "../elements/SubHeadlineElement";
import {TextElement} from "../elements/TextElement";
import {RedirectElement} from "../elements/RedirectElement";
import {LinkElement} from "../elements/LinkElement";
import {AmountElement} from "../elements/AmountElement";
import {RedirectButtonElement} from "../elements/RedirectButtonElement";
import {CreditForm} from "../forms/CreditForm";
import {DebitForm} from "../forms/DebitForm";
import {IframeElement} from "../elements/IframeElement";
import {TableElement} from "../elements/TableElement";
import {QrCodeElement} from "../elements/QrCodeElement";
import {Accordion} from "../elements/Accordion";
import {DynamicForm} from "../forms/DynamicForm";
import {RowElement} from "../elements/RowElement";
import {ContainerElement} from "../elements/ContainerElement";
import {ImageElement} from "../elements/ImageElement";
import {PaymentMethodElement} from "../elements/PaymentMethodElement";
import {ApplePayButtonElement} from "../elements/ApplePayButtonElement";

// naming convention (to avoid name collision with React Components and class names):
// PageComponent, PageElement

export function getUrlParam(name: string): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');

    let url = window.location.href;
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getEnumValues(enumObject: Object): string[] {
    return Object.keys(enumObject).map(function (e) {
        // TODO
        // @ts-ignore
        return enumObject[e];
    })
}

export function findPageComponentByType(sessionData: SessionData | null, type: PageComponentType): PageComponent[] {
    if (sessionData) {
        return sessionData.page.components.filter(el => {
            return el.type === type
        })
    } else {
        return []
    }
}

export function findPageElementsByType(elements: PageElement[], type: PageElementType): PageElement[] {
    return elements.filter(el => {
        return el.type === type
    })
}

// find Page Elements in whole page structure
export function globalFindPageElementsByType(sessionData: SessionData | null, type: PageElementType): PageElement[] {
    let elements: PageElement[] = [];
    if (sessionData) {
        for (let i = 0; i < sessionData.page.components.length; i++) {
            elements = elements.concat(elements, findPageElementsByType(sessionData.page.components[i].elements, type));
        }
        return elements;
    } else {
        return [];
    }
}

export function prepareCommonPageComponentProps(component: PageComponent, appProps: AppProps): CommonPageComponentProps {
    return {
        ...appProps,
        elements: component.elements,
        name: component.type,
        key: component.id,
        config: component.config
    }
}

export function preparePageElementProps(pageElement: PageElement, cpcProps: CommonPageComponentProps, epcProps: ExtendedPageComponentsProps): PageElementProps {

    let combinedProps = {...cpcProps, ...epcProps};
    let preparedProps: PageElementProps;

    // In general, element can't have nested elements
    // but accordion-element (EDIT: now also ROW and CONTAINER) is special case (it is like Component)

    if (pageElement.type === PageElementType.ACCORDION_SUB_ELEMENT) {
        combinedProps.elements = pageElement.elements;
        combinedProps = removeProperty(combinedProps, "accordion_elements") as PageElementProps;
    }

    if (pageElement.type !== PageElementType.ACCORDION_SUB_ELEMENT) {
        combinedProps = removeProperty(combinedProps, "elements") as PageElementProps
    }

    if (pageElement.type === PageElementType.ACCORDION_MAIN_ELEMENT) {
        combinedProps.accordion_elements = pageElement.accordion_elements;
    }

    preparedProps = {
        ...combinedProps,
        config: pageElement.config,
        key: pageElement.id,
        name: pageElement.type,
        id: pageElement.id
    }

    // We have to "push down" creditcard_schemes (from accordion-element) to credit-form element
    if (pageElement.type === PageElementType.CREDIT_FORM && cpcProps.config) {
        if (preparedProps.config ) {
            preparedProps.config = {
                ...preparedProps.config,
                creditcard_schemes: cpcProps.config.creditcard_schemes
            }
        } else {
            preparedProps.config = {
                creditcard_schemes: cpcProps.config.creditcard_schemes
            }
        }
    }

    // TODO:
    // I think we should change this "if" statement to the same as in "row_elements" (see below)
    if(pageElement.type === PageElementType.DYNAMIC_FORM) {
        preparedProps.form_elements = pageElement.form_elements
    }

    if(pageElement.row_elements) {
        preparedProps.row_elements = pageElement.row_elements;
    }

    if(pageElement.container_elements) {
        preparedProps.container_elements = pageElement.container_elements;
    }

    return preparedProps;
}

// workaround for Components that use only CommonPageComponentProps (Header, Footer, Action)
// consider to make actionButton (s. ExtendedPageComponentsProps) as optional
export function createFakeExtendedComponentProps(): ExtendedPageComponentsProps {
    return {
        actionButton: {
            onClick: () => {
                return
            },
            disabled: false,
            action: ''
        },
        setActionButtonOptions: (options) => {return}
    }
}

// remove property without mutating original object
function removeProperty(originalObj: {}, property: string): {} {
    return Object.keys(originalObj).reduce((object, key) => {
        if (key !== property) {
            // @ts-ignore
            object[key] = originalObj[key]
        }
        return object
    }, {})
}

export function createPageElementByType(pageElement: PageElement, componentProps: CommonPageComponentProps, extendedComponentProps: ExtendedPageComponentsProps): JSX.Element {
    let element: { element: JSX.Element, type: PageElementType };

    let defaultWidth = (config: Config | null): string => {
        return `col-${getConfigStyleProp(config, StyleProp.WIDTH) || 12}`;
    }

    switch (pageElement.type) {
        case PageElementType.GOOGLE_PAY_BUTTON:
            element = {
                element: <GooglePayButtonElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.APPLE_PAY_BUTTON:
            element = {
                element: <ApplePayButtonElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.ACTION_BUTTON:
            element = {
                element: <ActionButtonElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.HEADLINE:
            element = {
                element: <HeadlineElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.SUB_HEADLINE:
            element = {
                element: <SubHeadlineElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.TEXT:
            element = {
                element: <TextElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.DEBIT_FORM:
            element = {
                element: <DebitForm {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.CREDIT_FORM:
            element = {
                element: <CreditForm {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.DYNAMIC_FORM:
            element = {
                element: <DynamicForm {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.REDIRECT_BUTTON:
            element = {
                element: <RedirectButtonElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.REDIRECT:
            element = {
                element: <RedirectElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.CLOSE_BUTTON:
            element = {
                element: <CloseButtonElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.AMOUNT:
            element = {
                element: <AmountElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.IFRAME:
            element = {
                element: <IframeElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.ACCORDION_MAIN_ELEMENT:
            element = {
                element: <Accordion {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.LINK:
            element = {
                element: <LinkElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.TABLE:
            element = {
                element: <TableElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.QR_CODE:
            element = {
                element: <QrCodeElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.IMAGE:
            element = {
                element: <ImageElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.PAYMENT_METHOD:
            element = {
                element: <PaymentMethodElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.ROW:
            element = {
                element: <RowElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        case PageElementType.CONTAINER:
            element = {
                element: <ContainerElement {...preparePageElementProps(pageElement, componentProps, extendedComponentProps)} />,
                type: pageElement.type
            }
            break;
        default:
            console.log(`%cUnknown element name: ${pageElement.type}`, 'background: #dd0000; color: white; padding: 10px;');
            element = {
                element: <p className={`element-${pageElement.type}`}></p>,
                type: PageElementType.UNKNOWN
            }
    }

    // By default, each element is one line (if width is not present)
    // Special case: links, row, container, image
    // TODO In future we could remove hardcoded wrapper and base whole structure on backend row/component/element
    switch (element.type) {
        case PageElementType.LINK:
            return <span className={`wrapper-${element.type}`} key={pageElement.id}>{element.element}
                </span>
        case PageElementType.ROW:
        case PageElementType.CONTAINER:
        case PageElementType.IMAGE:
        case PageElementType.PAYMENT_METHOD:
            return element.element;
        default:
            return <div className={`
            ${defaultWidth(pageElement.config)} 
            wrapper-${element.type} 
            ${getConfigBootstrapClasses(pageElement.config)}`.trim()} key={pageElement.id}>
                {element.element}
            </div>
    }
}

// After Accordion implementation, function can create nested elements as PageComponents (e.g. accordion-element from response)
export function createPageComponentStructure(cpcProps: CommonPageComponentProps, epcProps: ExtendedPageComponentsProps, type?: PageComponentType | PageElementType): JSX.Element[] | null {
    let componentStructure: JSX.Element[] = [];
    let elements;

    elements = cpcProps.elements;

    if (elements) {
        elements.sort((a, b) => {
            return a.id - b.id;
        });

        elements.forEach(element => {
            componentStructure.push(createPageElementByType(element, cpcProps, epcProps));
        })

        return componentStructure.length > 0 ? componentStructure : null;
    } else {
        return null;
    }
}

export function removeAllWhiteChars(value: string) {
    return value.replace(/\s/g, '');
}

export function groupBy(value: string, charsPerGroup: number = 4): string {
    let groupedValue = '';
    let cleanValue = removeAllWhiteChars(value);

    for (let i = 0; i < cleanValue.length; i++) {
        groupedValue += cleanValue[i];
        if ((i + 1) % charsPerGroup === 0) {
            groupedValue += ' ';
        }
    }
    // [1] without trim(), cursor will be set after space char
    return groupedValue;
    // return groupedValue.trim();
}

// Function calculates cursor position in grouped string (e.g. IBAN, CC number)
// So keep in mind that value = groupBy() should be used before
export function calculateCursorPosition(event: FormEvent, value: string, digitsOnly?: boolean): number {
    let input = event.target as HTMLInputElement;
    let inputPos: number = input.selectionStart as number;
    let inputType: InputTypes = (event.nativeEvent as InputEvent).inputType as InputTypes;
    let currentPos = inputPos;
    let text = (event.nativeEvent as InputEvent).data; // inserted text

    // trim() because space is added in groupBy() [1]
    let isCursorAtTheEnd: () => boolean = () => {
        return currentPos === value.trim().length;
    }

    let getCharAt: (pos: number) => string = (pos) => {
        return value.substr(pos, 1);
    }

    if (inputType === InputTypes.INSERT_TEXT && text !== ' ') {
        // If cursor is at the end or before space char
        if (isCursorAtTheEnd() || (getCharAt(currentPos - 1) === ' ') || (getCharAt(currentPos) === ' ')) {
            currentPos = currentPos + 1;
        }
    }

    // space char has special case
    if (inputType === InputTypes.INSERT_TEXT && text === ' ') {
        currentPos = currentPos - 1;
    }

    if((inputType === InputTypes.INSERT_TEXT) &&
        digitsOnly &&
        text &&
        /^[a-zA-Z]+$/.test(text)) {
        currentPos = currentPos - 1;
    }

    // Backspace
    if (inputType === InputTypes.DELETE_CONTENT_BACKWARD) {
        if (getCharAt(currentPos - 1) === ' ') {
            currentPos = currentPos - 1;
        }
    }

    // Delete
    if (inputType === InputTypes.DELETE_CONTENT_FORWARD) {
        if (getCharAt(currentPos) === ' ') {
            currentPos = currentPos + 1;
        }
    }

    return currentPos;
}

export function getAction(config: Config | null, lang: LanguageSymbol): string {
    let action: Label;

    if (config === null || config.action === null) {
        console.warn('No action. Config object or action is empty.');
        return '';
    }

    if ((typeof config.action) === 'string') {
        return config.action as string;
    }

    action = config.action as Label;

    if (action[lang]) {
        return action[lang];
    } else {
        if (action.default) {
            return action.default;
        } else {
            console.warn('No action. All properties are empty.');
            return '';
        }
    }
}

// Currently returning string is not based on language
export function getIcon(config: Config | null): string {
    if (config && config.icon !== undefined) {
        if (config.icon !== '') {
            return config.icon;
        } else {
            console.warn('Empty icon config property.');
            return '';
        }
    } else {
        return '';
    }
}

export function getLabelTranslation(config: Config | null, lang: LanguageSymbol): string {
    if (config === null || config.label === undefined) {
        console.warn('No label translation. Config object is empty.');
        return '';
    }

    if (config.label[lang]) {
        return config.label[lang];
    } else {
        if (config.label.default) {
            return config.label.default;
        } else {
            console.warn('No label translation. All properties are empty.');
            return '';
        }
    }
}

/**
 * Return 'col-12 col-sm-12' or custom col- width
 * @param config
 */
export function prepareRwdClass(config: Config | null): string {
    let width: number | null;

    width = getConfigStyleProp(config, StyleProp.WIDTH) || 12;

    return `col-12 col-sm-${width.toString()}`;
}

// Currently based on type: number
export function getConfigStyleProp(config: Config | null, propName: StyleProp): number | undefined {
    let propValue: number | undefined

    if (config && config.style && config.style[propName]) {
        propValue = config.style[propName];
    }

    return propValue;
}


export function getConfigBootstrapClasses(config: Config | null): string {
    if(config?.style?.bootstrap) {
        return config.style.bootstrap.join(' ');
    } else {
        return '';
    }
}

export function prepareConfigInlineStyles(config: Config | null): object {
    let maxHeight = getConfigStyleProp(config, StyleProp.MAX_HEIGHT);
    let maxWidth = getConfigStyleProp(config, StyleProp.MAX_WIDTH);

    return {
        maxHeight: maxHeight + 'px',
        maxWidth: maxWidth + 'px'
    }
}

export function handleRedirection(config: Config | null, lang: LanguageSymbol): void {
    window.location.replace(getAction(config, lang));
}

// convert Eurocents to Euro with decimal separator based on lang
export function convertToEuro(amount: string, lang: LanguageSymbol): string {
    // for other languages than DE use "."
    let decimalSeparator = lang === LanguageSymbol.DE ? ',' : '.';

    return (parseInt(amount) / 100).toFixed(2).toString().replace('.', decimalSeparator);
}

export function keepDigitsOnly(value: string): string {
    return value.replace(/\D+/g, '');
}

// deleting using Backspace or delete
export function isDeletingChar(inputType: string): boolean {
    return ['deleteContentForward', 'deleteContentBackward'].indexOf(inputType) !== -1;
}

// amount must be between clean <span></span> tags
export function prepareAmountValue(config: Config | null, lang: LanguageSymbol): string {
    let text;
    let regex = /<span>(.*?)<\/span>/g;

    text = getLabelTranslation(config, lang);

    return text.replace(regex, (match, string, offset) => {
        return convertToEuro(string, lang);
    })
}

export function mapCcTypes2Id(config: Config | null): string[] {
    let ids: string[] = [];
    let ccTypes: CreditCardTypes[];

    if(config?.creditcard_schemes) {
        ccTypes = config.creditcard_schemes;

        ccTypes.forEach((name: CreditCardTypes) => {
            switch(name) {
                case CreditCardTypes.AMEX:
                    ids.push('1');
                    break;
                case CreditCardTypes.DINERS:
                    // For Dinners, we should display it and Discover icon
                    ids.push('3');
                    ids.push('10');
                    break;
                case CreditCardTypes.JCB:
                    ids.push('4');
                    break;
                case CreditCardTypes.CUP:
                    ids.push('11');
                    break;
            }
        });


        // Special case for VISA and MC
        // These icons should be at first place
        if(ccTypes.includes(CreditCardTypes.MASTERCARD)) {
            ids.unshift('8');
        }

        if(ccTypes.includes(CreditCardTypes.VISA)) {
            ids.unshift('7');
        }
    }

    return ids;
}

export function language2locale(lang: LanguageSymbol): LocaleSymbol {
        switch (lang) {
            case LanguageSymbol.EN:
                return LocaleSymbol.EN_US;
            default:
                return LocaleSymbol.DE_DE;
        }
}

export function log2Html(parentSelector: string, msg: string, prefix: string = '') {
    let div = document.createElement('div');
    div.innerText = prefix + msg;
    document.querySelector(parentSelector)?.appendChild(div);
}
