import React from "react";
import {CommonPageComponentProps} from "../utils/types";
import {createFakeExtendedComponentProps, createPageComponentStructure} from "../utils/utils";

export class ActionComponent extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <>
            {/*workaround for Component that use only CommonPageComponentProps*/}
            {createPageComponentStructure(this.props, createFakeExtendedComponentProps())}
        </>
    }
}
