import React from "react";
import {
    AccordionElementProps,
    CommonPageComponentProps,
    ExtendedPageComponentsProps,
    PageElement,
    PageElementProps
} from "../utils/types";
import {preparePageElementProps} from "../utils/utils";
import {AccordionElement} from "./AccordionElement";

interface AccordionState {
    selectedId: number // accordion-element id,
    hiddenIds: Set<number>
}

export class Accordion extends React.Component<PageElementProps, AccordionState> {

    constructor(props: PageElementProps) {
        super(props);

        this.state = {
            selectedId: this.getFirstElementId(),
            hiddenIds: new Set()
        }

        this.setSelectedId = this.setSelectedId.bind(this);
    }

    render() {
        return <>
            {this.prepareAccordionStructure()}
        </>
    }

    // We have to set default selectedId in state
    getFirstElementId(): number {
        if (this.props.accordion_elements?.length) {
            return this.props.accordion_elements[0].id;
        } else {
            console.warn('No accordion-elements in accordion');
            return -1;
        }
    }

    prepareAccordionElementProps(el: PageElement): AccordionElementProps {
        return {
            ...preparePageElementProps(el, this.props as CommonPageComponentProps, this.props as ExtendedPageComponentsProps),
            selectedId: this.state.selectedId,
            setSelectedId: this.setSelectedId,
            id: el.id,
            toggleVisibilityInAccordion: this.toggleVisibilityInAccordion.bind(this)
        }
    }

    prepareAccordionStructure(): JSX.Element[] | null {
        let accordionElements: JSX.Element[] = [];
        if (this.props.accordion_elements) {
            // TODO
            // sorting is needed?
            this.props.accordion_elements.forEach((el, index) => {
                if(!this.state.hiddenIds.has(el.id)) {
                    accordionElements.push(<AccordionElement {...this.prepareAccordionElementProps(el)} />)
                }
            });
        } else {
            return null;
        }

        return accordionElements.length > 0 ? accordionElements : null;
    }

    setSelectedId(id: number): void {
        this.setState({selectedId: id})
    }

    toggleVisibilityInAccordion(id: number | undefined, state: 'hide' | 'show'): void {
        if(id) {
            let ids = this.state.hiddenIds;
            if(state === 'hide') {
                ids.delete(id);
            }

            if(state === 'hide') {
                ids.add(id);
            }

            this.setState({hiddenIds: ids})

        } else {
            console.warn('Missing accordionId');
        }

    }
}
