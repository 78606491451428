import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {prepareAmountValue} from "../utils/utils";

export class AmountElement extends React.Component<CommonPageComponentProps, {}> {

    render() {
        return <div className={`element-${PageElementType.AMOUNT}`}>{prepareAmountValue(this.props.config, this.props.lang)}</div>
    }
}
