import {LanguageSymbol} from "./types";

const LANG_KEY_NAME: string = 'pay-secupay-lang';

function getLang(): LanguageSymbol | null {
    if (sessionStorage) {
        return sessionStorage.getItem(LANG_KEY_NAME) as LanguageSymbol;
    } else {
        console.warn('SessionStorage is not available');
        return null;
    }
}

function setLang(lang: LanguageSymbol) {
    if (sessionStorage) {
        sessionStorage.setItem(LANG_KEY_NAME, lang);
    } else {
        console.warn('SessionStorage is not available');
    }
}


const LocalStorage = {
    getLang,
    setLang
}

export default LocalStorage
