import React, {createRef} from "react";
import {AccordionElementProps, CcSide, CommonPageComponentProps, PageElementType, PaymentMethod} from "../utils/types";
import {getIcon, getLabelTranslation, mapCcTypes2Id} from "../utils/utils";
import {PaymentMethodIcon} from "./PaymentMethodIcon";
import {CreditCardIcon} from "./CreditCardIcon";
import {ContentComponent} from "../components/ContentComponent";

// AccordionElement is a nested Component because it includes elements
export class AccordionElement extends React.Component<AccordionElementProps> {

    private contentRef = createRef<HTMLInputElement>();

    render() {
        return <>
            {this.createAccordionSubElementStructure()}
        </>
    }

    componentDidUpdate() {
        // For collapse/expand effect we have to calculate height explicitly
        // (CSS transition won't handle height: auto)
        // So we use trick with max-height (and scrollHeight to get actual height of element)
        let element = this.contentRef.current as HTMLInputElement;

        if (element.style) {
            if (this.isSelected()) {
                element.style.maxHeight = `${element.scrollHeight}px`;
            } else {
                element.style.maxHeight = '0';
            }
        }
    }

    createAccordionSubElementStructure(): JSX.Element {
        return <div className={`element-${PageElementType.ACCORDION_SUB_ELEMENT} ` + (this.isSelected() ? "expanded" : "collapsed")}>
            <div className="header" onClick={e => this.handleOnClick(e, this.props.id)}>
                <div className="icons">{this.setAccordionElementIcon()}</div>
                <div className="name">{getLabelTranslation(this.props.config, this.props.lang)}</div>
            </div>
            <div ref={this.contentRef} className="content">
                <ContentComponent {...{...this.props, accordionId: this.props.id} as CommonPageComponentProps} />
            </div>
        </div>
    }

    private isSelected(): boolean {
        return this.props.selectedId === this.props.id;
    }

    setAccordionElementIcon(): JSX.Element | JSX.Element[] | null {
        let icon = getIcon(this.props.config);

        // currently, we only use PaymentMethods' icons
        switch (icon) {
            case PaymentMethod.APPLE_PAY:
            case PaymentMethod.GOOGLE_PAY:
            case PaymentMethod.PAYPAL:
            case PaymentMethod.PAY_IN_ADVANCE:
            case PaymentMethod.DEBIT:
            case PaymentMethod.SOFORT:
            case PaymentMethod.INVOICE:
            case PaymentMethod.EASY_CREDIT:
            case PaymentMethod.EPS:
            case PaymentMethod.GIROPAY:
            case PaymentMethod.AIIA:
                return <PaymentMethodIcon paymentMethod={icon} lang={this.props.lang}/>
            case PaymentMethod.CREDIT_CARD:
                return this.getCreditCardIcons();
            default:
                console.warn('Unrecognized accordion icon: ' + icon);
                return null;
        }
    }

    getCreditCardIcons(): JSX.Element[] {
        let ccIcons: JSX.Element[] = [];

            mapCcTypes2Id(this.props.config).forEach((id, index) => {
                ccIcons.push(<CreditCardIcon ccCompanyId={id} cardSide={CcSide.FRONT} key={index}/>);
            })

        if(ccIcons.length === 0) {
            console.warn('No credit card icons to be shown');
        }

        return ccIcons;
    }

    handleOnClick(event: React.MouseEvent<HTMLDivElement>, id: number) {
            this.props.setSelectedId(id);
    }
}
