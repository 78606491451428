module.exports = [
    {
        id: '1',
        name: 'American Express',
        /*
         * BIN/IIN ranges:
         *   3400'0000 - 3499'9999
         *   3700'0000 - 3799'9999
         */
        pattern: /^3[47]/,
        valid_length: [15]
    },
    {
        id: '3',
        name: 'Diners',
        /*
         * BIN/IIN ranges:
         *   3000'0000 - 3059'9999
         *   3600'0000 - 3699'9999
         *   3800'0000 - 3999'9999
         */
        pattern: /^3(0[0-5]|[689])/,
        valid_length: [14, 15, 16, 17, 18, 19]
    },
    {
        id: '4',
        name: 'JCB',
        /*
         * BIN/IIN ranges:
         *   3088'0000 - 3102'9999
         *   3112'0000 - 3120'9999
         *   3158'0000 - 3159'9999
         *   3337'0000 - 3349'9999
         *   3528'0000 - 3589'9999
         */
        pattern: /^3(52[89]|5[3-8]|08[89]|09|10[012]|11[2-9]|120|15[89]|33[7-9]|34)/,
        valid_length: [16, 17, 18, 19]
    },
    {
        id: '7',
        name: 'VISA',
        /*
         * BIN/IIN ranges:
         *   4000'0000 - 4999'9999
         */
        pattern: /^4/,
        valid_length: [16, 19]
    },
    {
        id: '8',
        name: 'MasterCard',
        /*
         * BIN/IIN ranges:
         *   2221'0000 - 2720'9999
         *   5100'0000 - 5599'9999
         */
        pattern: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)/,
        valid_length: [16, 19]
    },
    {
        id: '9',
        name: 'Maestro',
        /*
         * BIN/IIN ranges:
         *   6390'0000 - 6390'9999
         *   6700'0000 - 6799'9999
         */
        pattern: /^6(390|7)/,
        valid_length: [13, 14, 15, 16, 17, 18, 19]
    },
    {
        id: '10',
        name: 'Discover',
        /*
         * BIN/IIN ranges:
         *   6011'0000 - 6011'0999
         *   6011'2000 - 6011'4499
         *   6011'7400 - 6011'7499
         *   6011'7700 - 6011'7999
         *   6011'8600 - 6011'9999
         *   6221'2600 - 6229'2599
         *   6440'0000 - 6599'9999
         */
        pattern: /^(6011|622[1-9]|64[4-9]|65)/, // (the first 5 ranges are simplified to 6011'****)
        valid_length: [16, 17, 18, 19]
    },
    {
        id: '11',
        name: 'China UnionPay',
        /*
         * BIN/IIN ranges:
         *   6240'0000 - 6269'9999
         *   6282'0000 - 6288'9999
         *   8100'0000 - 8171'9999
         */
        pattern: /^(62([4-6]|8[2-8])|81([0-6]|7[01]))/,
        valid_length: [16, 17, 18, 19]
    },
];
