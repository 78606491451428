import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getAction} from "../utils/utils";

export class CloseButtonElement extends React.Component<CommonPageComponentProps, {}> {

    constructor(props: CommonPageComponentProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        return this.prepareCloseButton();
    }

    private handleClick() {
        window.location.href = getAction(this.props.config, this.props.lang);
    }

    prepareCloseButton(): JSX.Element | null {
        const NO_BUTTON_TXT = 'close';
        let action = getAction(this.props.config, this.props.lang).toLowerCase();

        if(!action || action === NO_BUTTON_TXT) {
            return null;
        } else {
            return <FontAwesomeIcon icon={faXmark} onClick={this.handleClick} className={`element-${PageElementType.CLOSE_BUTTON}`} />
        }
    }
}
