import React, {FormEvent} from "react";
import {
    DebitFormFields,
    DebitFormStructure,
    FormErrorClasses,
    PageElementProps,
    PageElementType
} from "../utils/types";
import Validator from "../utils/Validator";
import {calculateCursorPosition, groupBy, removeAllWhiteChars} from "../utils/utils";

export class DebitForm extends React.Component<PageElementProps, {}> {

    constructor(props: PageElementProps) {
        super(props);

        this.props.setActionButtonOptions({
            onClick: () => this.handleSubmit()
        })

        this.handleOnInput = this.handleOnInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setFormDefaultValues();
    }

    render() {
        let form = this.props.forms[PageElementType.DEBIT_FORM];
        if (!form) {
            return null
        } else {
            return <>
                <div className={`row mt-3 mb-3 element-${PageElementType.DEBIT_FORM}`}>
                    {/*TODO*/}
                    {/*Create custom element Input*/}
                    <div
                        className={`col-12 col-sm-6 ${this.props.formsErrors[PageElementType.DEBIT_FORM][DebitFormFields.OWNER] ? FormErrorClasses.GENERAL : ''}`}>
                        <label className="form-label">{this.props.getTranslation(`${PageElementType.DEBIT_FORM}.${DebitFormFields.OWNER}`)}</label>
                        <input className="form-control" type="text" name={DebitFormFields.OWNER}
                               value={form[DebitFormFields.OWNER] || ''}
                               onInput={event => this.handleOnInput(event)}
                               maxLength={50}
                               onBlur={event => this.props.trimValue(event, PageElementType.DEBIT_FORM)}/>
                        {this.props.formsErrors[PageElementType.DEBIT_FORM][DebitFormFields.OWNER] &&
                        <label
                            className={"mt-1 " + FormErrorClasses.MSG}>{this.props.getTranslation(this.props.formsErrors[PageElementType.DEBIT_FORM][DebitFormFields.OWNER])}</label>}
                    </div>
                    <div
                        className={`col-12 col-sm-6 mt-3 mt-sm-0 ${this.props.formsErrors[PageElementType.DEBIT_FORM][DebitFormFields.IBAN] ? FormErrorClasses.GENERAL : ''}`}>
                        <label className="form-label">{this.props.getTranslation(`${PageElementType.DEBIT_FORM}.${DebitFormFields.IBAN}`)}</label>
                        <input className="form-control" type="text" name={DebitFormFields.IBAN}
                               value={form[DebitFormFields.IBAN] || ''}
                               onInput={event => this.handleOnInput(event)}
                               maxLength={30}
                               onBlur={event => this.props.trimValue(event, PageElementType.DEBIT_FORM)}/>
                        {this.props.formsErrors[PageElementType.DEBIT_FORM][DebitFormFields.IBAN] &&
                        <label
                            className={"mt-1 " + FormErrorClasses.MSG}>{this.props.getTranslation(this.props.formsErrors[PageElementType.DEBIT_FORM][DebitFormFields.IBAN])}</label>}
                    </div>
                </div>
            </>
        }
    }

    private setFormDefaultValues() {
        this.props.setFormFieldValues({
            [DebitFormFields.IBAN]: '',
            [DebitFormFields.OWNER]: ''
        }, PageElementType.DEBIT_FORM);
    }

    private handleSubmit() {
        if (this.isFormValid()) {
            this.sendRequest();
        }
    }

    private sendRequest() {
        let body = this.prepareRequestBody();

        if (body) {
            this.props.callBackend(this.props.actionButton.action, {
                method: "POST",
                data: body
            })
        } else {
            console.warn('Bad request body');
        }
    }

    private prepareRequestBody(): object | null {
        let form = this.props.forms[PageElementType.DEBIT_FORM];

        if (form) {
            return {
                id: this.props.transaction.id,
                server: this.props.server,
                container: {
                    type: "bank_account",
                    private: {
                        [DebitFormFields.OWNER]: form[DebitFormFields.OWNER],
                        [DebitFormFields.IBAN]: removeAllWhiteChars(form[DebitFormFields.IBAN])
                    }
                }
            }
        } else {
            return null;
        }
    }

    private handleOnInput(event: FormEvent) {
        let input = event.target as HTMLInputElement;
        let name = input.name as DebitFormFields;
        let form = (this.props.forms[PageElementType.DEBIT_FORM] as DebitFormStructure);
        let formErrorMsg = (this.props.formsErrors[PageElementType.DEBIT_FORM] as DebitFormStructure);
        let value = input.value;
        let cursorPosition: number;

        // on input remove error msg
        this.props.setFormFieldErrorMsg({
            ...formErrorMsg,
            [name]: ''
        }, PageElementType.DEBIT_FORM)

        if (name === DebitFormFields.IBAN) {
            value = groupBy(value).toUpperCase();
            cursorPosition = calculateCursorPosition(event, value);
        }

        // React recalculates cursor position after setState,
        // that's why we have to save cursor position before setState
        // and set it after
        this.props.setFormFieldValues({
            ...form,
            [name]: value
        }, PageElementType.DEBIT_FORM, () => {
            if (name === DebitFormFields.IBAN) {
                input.selectionStart = cursorPosition;
                input.selectionEnd = cursorPosition;
            }
        })
    }

    private isFormValid(): boolean {
        let errors: DebitFormStructure = this.props.formsErrors[PageElementType.DEBIT_FORM];

        errors[DebitFormFields.OWNER] = Validator.checkFieldValidity(this.props.forms[PageElementType.DEBIT_FORM], DebitFormFields.OWNER, [
            {
                validator: 'isNotEmpty',
                errorTranslationKey: `${PageElementType.DEBIT_FORM}.invalid_owner`
            },
            {
                validator: 'isLongerThan',
                errorTranslationKey: `${PageElementType.DEBIT_FORM}.invalid_owner`
            }
        ]);

        errors[DebitFormFields.IBAN] = Validator.checkFieldValidity(this.props.forms[PageElementType.DEBIT_FORM], DebitFormFields.IBAN, [
                {
                    validator: 'isNotEmpty',
                    errorTranslationKey: `${PageElementType.DEBIT_FORM}.invalid_iban`
                },
                {
                    validator: 'isIban',
                    errorTranslationKey: `${PageElementType.DEBIT_FORM}.invalid_iban`
                }
            ]
        );

        this.props.setFormFieldErrorMsg(errors, PageElementType.DEBIT_FORM);

        return Validator.hasNoErrors(errors);
    }
}
