import React from "react";
import {PageElementProps, PageElementType} from "../utils/types";
import {createPageElementByType, getConfigBootstrapClasses} from "../utils/utils";

export class RowElement extends React.Component<PageElementProps, {}> {

    render() {
        return <div className={`row element-${PageElementType.ROW} ${getConfigBootstrapClasses(this.props.config)}`.trim()}>
            {this.prepareContainerStructure()}
        </div>
    }

    prepareContainerStructure(): JSX.Element[] | null {
        let rowElements: JSX.Element[] = [];

        if(this.props.row_elements) {
            this.props.row_elements.forEach(el => {
                rowElements.push(createPageElementByType(el, this.props, this.props));
            })
            return rowElements;
        } else {
            return null;
        }
    }
}
