import React from "react";
import {CommonPageComponentProps, PageElementType} from "../utils/types";
import {getAction} from "../utils/utils";

export class IframeElement extends React.Component<CommonPageComponentProps, {}> {

    readonly DEFAULT_HEIGHT = '600px';

    render() {
        return <iframe
            title="3d secure iframe"
            className={`element-${PageElementType.IFRAME}`}
            src={this.getIframeSrc()}
            width="100%"
            sandbox="allow-scripts allow-forms allow-top-navigation"
            height={this.DEFAULT_HEIGHT}/>
    }

    getIframeSrc(): string {
        // currently param is hardcoded but in general it should be used when app is in fullscreen mode
        // for iframe remove it, then window.top will be used
        return getAction(this.props.config, this.props.lang) + '&RedirSameWindow=1';
    }
}

