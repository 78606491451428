import React, {createRef, FormEvent} from "react";
import {
    CcSide,
    CreditCardEncryptedData,
    CreditCardType,
    CreditFormFields,
    CreditFormStructure,
    FormErrorClasses,
    PageElementProps,
    PageElementType
} from "../utils/types";
import Validator from "../utils/Validator";
import {calculateCursorPosition, groupBy, isDeletingChar, keepDigitsOnly} from "../utils/utils";
import {CreditCardIcon} from "../elements/CreditCardIcon";

interface CreditState {
    ccCompanyId: string // id from credit-card-types.js
    ccSide: CcSide
}

const SUPPORTED_CREDIT_CARDS_IDS = require('./../utils/credit-card/supported-credit-cards');
const CREDIT_CARD_TYPES: CreditCardType[] = require('./../utils/credit-card/credit-card-types');
const KEY_FILENAME = 'transact_pay_secupay';
const maxCcNumberLength = 16;
const ccGroupBy = 4;

export class CreditForm extends React.Component<PageElementProps, CreditState> {

    private refCardNumber = createRef<HTMLInputElement>();
    private refCvc = createRef<HTMLInputElement>();
    private refExpDate = createRef<HTMLInputElement>();

    constructor(props: PageElementProps) {
        super(props);

        let scriptTag1 = document.createElement('script');
        scriptTag1.src = '//cc.secupay.com/js/tripledes.js';
        scriptTag1.async = true;
        document.head.appendChild(scriptTag1);
        let scriptTag2 = document.createElement('script');
        scriptTag2.src = '//cc.secupay.com/js/rsa.all.min.js';
        scriptTag2.async = true;
        document.head.appendChild(scriptTag2);
        let scriptTag3 = document.createElement('script');
        scriptTag3.src = '//cc.secupay.com/js/pay-secupay/credit-card-encryption.js';
        scriptTag3.async = true;
        document.head.appendChild(scriptTag3);

        this.state = {
            ccCompanyId: '',
            ccSide: CcSide.FRONT
        }

        this.props.setActionButtonOptions({
            onClick: () => this.handleSubmit()
        })

        this.handleOnInput = this.handleOnInput.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setFormDefaultValues();
    }

    render() {
        let form = this.props.forms[PageElementType.CREDIT_FORM];
        if (!form) {
            return null
        } else {
            return <>
                <div className={`row mt-3 mb-3 element-${PageElementType.CREDIT_FORM}`}>
                    <div
                        className={`col-12 col-md-6 ${this.props.formsErrors[PageElementType.CREDIT_FORM][CreditFormFields.OWNER] ? FormErrorClasses.GENERAL : ''}`}>
                        <label className="form-label"
                               htmlFor={CreditFormFields.OWNER}>{this.props.getTranslation(`${PageElementType.CREDIT_FORM}.${CreditFormFields.OWNER}`)}</label>
                        <input className="form-control" type="text" name={CreditFormFields.OWNER}
                               value={form[CreditFormFields.OWNER] || ''}
                               onInput={event => this.handleOnInput(event)}
                               maxLength={50}
                               id={CreditFormFields.OWNER}
                               onBlur={event => this.handleOnBlur(event)}/>
                        {this.props.formsErrors[PageElementType.CREDIT_FORM][CreditFormFields.OWNER] &&
                        <label className={"mt-1 " + FormErrorClasses.MSG}>
                            {this.props.getTranslation(this.props.formsErrors[PageElementType.CREDIT_FORM][CreditFormFields.OWNER])}
                        </label>}
                    </div>
                    <div className={`col-12 col-md-6 mt-3 mt-md-0 ${this.hasCardDataErrors() ? FormErrorClasses.GENERAL : ''}`}>
                        <label className="form-label">{this.props.getTranslation(`${PageElementType.CREDIT_FORM}.card_data`)}</label>
                        <div className="card-data">
                            {/*CARD NUMBER*/}
                            <div
                                className={`wrapper-card-number ${this.props.formsErrors[PageElementType.CREDIT_FORM][CreditFormFields.CARD_NUMBER] ? FormErrorClasses.GENERAL : ''}`}>
                                <CreditCardIcon ccCompanyId={this.state.ccCompanyId} cardSide={this.state.ccSide}/>
                                <input className="form-control" type="text" name={CreditFormFields.CARD_NUMBER}
                                       value={form[CreditFormFields.CARD_NUMBER] || ''}
                                       onInput={event => this.handleOnInput(event)}
                                       onBlur={event => this.handleOnBlur(event)}
                                       onFocus={event => this.handleOnFocus(event)}
                                       maxLength={maxCcNumberLength + Math.floor(maxCcNumberLength / ccGroupBy) - 1}
                                       ref={this.refCardNumber}
                                       placeholder={this.props.getTranslation(`${PageElementType.CREDIT_FORM}.${CreditFormFields.CARD_NUMBER}`)}
                                       id={CreditFormFields.CARD_NUMBER}/>
                            </div>
                            {/*EXP DATE*/}
                            <div
                                className={`wrapper-exp-date ${this.props.formsErrors[PageElementType.CREDIT_FORM][CreditFormFields.EXP_DATE] ? FormErrorClasses.GENERAL : ''}`}>
                                <input className="form-control" type="text" name={CreditFormFields.EXP_DATE}
                                       value={form[CreditFormFields.EXP_DATE] || ''}
                                       onInput={event => this.handleOnInput(event)}
                                       onBlur={event => this.handleOnBlur(event)}
                                       onKeyUp={event => this.handleOnKeyUp(event)}
                                       maxLength={5}
                                       ref={this.refExpDate}
                                       id={CreditFormFields.EXP_DATE}
                                       placeholder={this.props.getTranslation(`${PageElementType.CREDIT_FORM}.exp_date_placeholder`)}/>
                            </div>
                            {/*CVC*/}
                            <div
                                className={`wrapper-cvc ${this.props.formsErrors[PageElementType.CREDIT_FORM][CreditFormFields.CVC] ? FormErrorClasses.GENERAL : ''}`}>
                                <input className="form-control" type="text" name={CreditFormFields.CVC}
                                       value={form[CreditFormFields.CVC] || ''}
                                       onInput={event => this.handleOnInput(event)}
                                       onFocus={event => this.handleOnFocus(event)}
                                       onBlur={event => this.handleOnBlur(event)}
                                       onKeyUp={event => this.handleOnKeyUp(event)}
                                       ref={this.refCvc}
                                       id={CreditFormFields.CVC}
                                       placeholder={this.props.getTranslation(`${PageElementType.CREDIT_FORM}.${CreditFormFields.CVC}`)}/>
                            </div>
                        </div>
                        {/*general error message*/}
                        {this.hasCardDataErrors() &&
                        <label className={"mt-1 " + FormErrorClasses.MSG}>
                            {this.getCcFormErrorMsg()}
                        </label>}
                    </div>

                </div>
            </>
        }
    }

    private setFormDefaultValues() {
        this.props.setFormFieldValues({
            [CreditFormFields.OWNER]: '',
            [CreditFormFields.CARD_NUMBER]: '',
            [CreditFormFields.EXP_DATE]: '',
            [CreditFormFields.CVC]: '',
        }, PageElementType.CREDIT_FORM);
    }

    private handleSubmit() {
        if (this.isFormValid()) {
            this.sendRequest();
        }
    }

    private sendRequest() {
        let body = this.prepareRequestBody();

        if (body) {
            this.props.callBackend(this.props.actionButton.action, {
                method: "POST",
                data: body
            })
        } else {
            console.warn('Bad request body');
        }
    }

    private prepareRequestBody(): object | null {
        let form = this.props.forms[PageElementType.CREDIT_FORM];
        let encryptedData = this.getEncryptedData();

        if (form && encryptedData) {
            return {
                id: this.props.transaction.id,
                server: this.props.server,
                // structure from payment-iframe
                container: {
                    type: "credit_card",
                    private: {
                        owner: form[CreditFormFields.OWNER],
                        expiration_date: form[CreditFormFields.EXP_DATE],
                        issuer: this.getCcCompanyName(),
                        pan: encryptedData.masked.card_number,
                        transact_container: encryptedData.crypted_container,
                        transact_skey_pubkey: encryptedData.crypted_skey,
                        transact_skey_keyname: KEY_FILENAME,
                    }
                }
            }
        } else {
            return null;
        }
    }

    private handleOnInput(event: FormEvent) {
        let input = event.target as HTMLInputElement;
        let name = input.name as CreditFormFields;
        let form = (this.props.forms[PageElementType.CREDIT_FORM] as CreditFormStructure);
        let errors = (this.props.formsErrors[PageElementType.CREDIT_FORM] as CreditFormStructure);
        let value = input.value;
        let nativeEvent = event.nativeEvent as InputEvent;
        let char = nativeEvent.data as string;
        let cursorPosition: number;

        // on input remove error msg
        this.props.setFormFieldErrorMsg({
            ...errors,
            [name]: ''
        }, PageElementType.CREDIT_FORM)

        if (name === CreditFormFields.CARD_NUMBER) {
            value = keepDigitsOnly(value);
            value = groupBy(value, ccGroupBy);
            cursorPosition = calculateCursorPosition(event, value, true);

            // when we reach max length
            if (keepDigitsOnly(value).length === maxCcNumberLength) {
                // set icon and go to next field, if cc number is valid
                this.setCreditCardIcon(value, this.refExpDate.current)
            } else {
                this.setState({
                    ccCompanyId: this.getCcCompanyId(value),
                    ccSide: CcSide.FRONT
                });
            }
        }

        if (name === CreditFormFields.EXP_DATE) {
            value = keepDigitsOnly(value);

            // e.g. 3
            if (/^[2-9]$/.test(value)) {
                value = `0${value}`;
            }

            // e.g. 13
            if (/^1[3-9]$/.test(value)) {
                value = `01${char}`;
            }

            // Adding "/" separator
            // when we input char (no deleting)
            if (value.length >= 2) {
                value = `${value.substr(0, 2)}/${value.substr(2)}`;
            }

            // when we are deleting e.g. 11/3, "/" should be removed too
            if (value.length === 3 && isDeletingChar(nativeEvent.inputType)) {
                value = value.substr(0, 2);
            }

            // We use Validator directly because value will be updated later
            if (value.length === 5) {
                if (!Validator.isExpDateValid(value)) {
                    this.props.setFormFieldErrorMsg({
                        ...errors,
                        [CreditFormFields.EXP_DATE]: `${PageElementType.CREDIT_FORM}.invalid_exp_date`
                    }, PageElementType.CREDIT_FORM)
                } else {
                    this.refCvc.current?.focus();
                }
            }
        }

        if (name === CreditFormFields.CVC) {
            value = keepDigitsOnly(value);
            if(this.isAmex()) {
                value = value.substr(0, 4);
            } else {
                value = value.substr(0, 3);
            }
        }

        // React recalculates cursor position after setState,
        // that's why we have to save cursor position before setState
        // and set it after
        this.props.setFormFieldValues({
            ...form,
            [name]: value
        }, PageElementType.CREDIT_FORM, () => {
            if (name === CreditFormFields.CARD_NUMBER) {
                input.selectionStart = cursorPosition;
                input.selectionEnd = cursorPosition;
            }
        })
    }

    private handleOnFocus(event: FormEvent) {
        let input = event.target as HTMLInputElement;
        let name = input.name as CreditFormFields;

        switch (name) {
            case CreditFormFields.CARD_NUMBER:
                this.props.trimValue(event, PageElementType.CREDIT_FORM, () => {
                    input.selectionStart = input.value.length;
                    input.selectionEnd = input.value.length;
                });
                break;
            case CreditFormFields.CVC:
                this.setState({ccSide: CcSide.BACK});
                break;
        }
    }

    private handleOnBlur(event: FormEvent) {
        let input = event.target as HTMLInputElement;
        let name = input.name as CreditFormFields;
        let value = input.value;
        let form = this.props.forms[PageElementType.CREDIT_FORM] as CreditFormStructure;
        let errors = (this.props.formsErrors[PageElementType.CREDIT_FORM] as CreditFormStructure);

        this.props.trimValue(event, PageElementType.CREDIT_FORM);

        if (name === CreditFormFields.CARD_NUMBER) {
            this.setCreditCardIcon(value);
        }

        if (name === CreditFormFields.EXP_DATE) {
            if (value !== '' && !Validator.isExpDateValid(value)) {
                this.props.setFormFieldErrorMsg({
                    ...errors,
                    [CreditFormFields.EXP_DATE]: `${PageElementType.CREDIT_FORM}.invalid_exp_date`
                }, PageElementType.CREDIT_FORM)
            }
        }

        if (name === CreditFormFields.CVC) {
            let ccNumber = form[CreditFormFields.CARD_NUMBER];
            let errors = (this.props.formsErrors[PageElementType.CREDIT_FORM] as CreditFormStructure);

            this.setCreditCardIcon(ccNumber);
            if (value !== '' && !Validator.hasLength(value, {length: this.calculateCvcLength()})) {
                this.props.setFormFieldErrorMsg({
                    ...errors,
                    [CreditFormFields.CVC]: `${PageElementType.CREDIT_FORM}.invalid_cvc`
                }, PageElementType.CREDIT_FORM)
            }
        }
    }

    private isFormValid(): boolean {
        let errors: CreditFormStructure = this.props.formsErrors[PageElementType.CREDIT_FORM];

        errors[CreditFormFields.OWNER] = Validator.checkFieldValidity(this.props.forms[PageElementType.CREDIT_FORM], CreditFormFields.OWNER, [
            {
                validator: 'isNotEmpty',
                errorTranslationKey: `mandatory_field`
            },
            {
                validator: 'hasAtLeast2Letters',
                errorTranslationKey: `${PageElementType.CREDIT_FORM}.invalid_owner`
            }
        ]);

        errors[CreditFormFields.CARD_NUMBER] = Validator.checkFieldValidity(this.props.forms[PageElementType.CREDIT_FORM], CreditFormFields.CARD_NUMBER, [
                {
                    validator: 'isNotEmpty',
                    errorTranslationKey: `mandatory_field`
                },
                {
                    validator: 'isCreditCardValid',
                    errorTranslationKey: `${PageElementType.CREDIT_FORM}.invalid_card_number`
                },
                {
                    validator: 'isCompanyIdSupported',
                    errorTranslationKey: `${PageElementType.CREDIT_FORM}.not_supported_card_type`,
                    params: {
                        config: this.props.config,
                        id: this.state.ccCompanyId
                    }
                }
            ]
        );

        errors[CreditFormFields.EXP_DATE] = Validator.checkFieldValidity(this.props.forms[PageElementType.CREDIT_FORM], CreditFormFields.EXP_DATE, [
                {
                    validator: 'isNotEmpty',
                    errorTranslationKey: `mandatory_field`
                },
                {
                    validator: 'isExpDateValid',
                    errorTranslationKey: `${PageElementType.CREDIT_FORM}.invalid_exp_date`
                }
            ]
        );

        errors[CreditFormFields.CVC] = Validator.checkFieldValidity(this.props.forms[PageElementType.CREDIT_FORM], CreditFormFields.CVC, [
                {
                    validator: 'isNotEmpty',
                    errorTranslationKey: `mandatory_field`
                },
                {
                    validator: 'hasLength',
                    errorTranslationKey: `${PageElementType.CREDIT_FORM}.invalid_cvc`,
                    params: {
                        length: this.calculateCvcLength()
                    }
                }
            ]
        );

        // this.setState({errors: errors});
        this.props.setFormFieldErrorMsg(errors, PageElementType.CREDIT_FORM);

        return Validator.hasNoErrors(errors);
    }

    getCcCompanyId(ccNumber: string): string {
        let companyId = '',
            i, ccType,
            matchedCcTypes = [],
            minLength = 6;

        if (ccNumber.length >= minLength) {
            for (i = 0; i < CREDIT_CARD_TYPES.length; i++) {
                ccType = CREDIT_CARD_TYPES[i];
                if (SUPPORTED_CREDIT_CARDS_IDS.indexOf(ccType.id) !== -1 && ccNumber.replace(/\s/g, '').match(ccType.pattern)) {
                    matchedCcTypes.push(ccType);
                }
            }
            // only if we recognized the one
            if (matchedCcTypes.length === 1) {
                companyId = matchedCcTypes[0].id;
            }
        }
        return companyId;
    }

    // function set credit card icon based on validation
    // optional: set focus to X field if cc number is valid
    setCreditCardIcon(value: string, field?: HTMLInputElement | null): void {
        let errors = (this.props.formsErrors[PageElementType.CREDIT_FORM] as CreditFormStructure);
        let companyId;

        if (value !== '' && !Validator.isCreditCardValid(value)) {
            this.setState({
                ccSide: CcSide.FRONT_INVALID,
                ccCompanyId: ''
            });
            this.props.setFormFieldErrorMsg({
                ...errors,
                [CreditFormFields.CARD_NUMBER]: `${PageElementType.CREDIT_FORM}.invalid_card_number`
            }, PageElementType.CREDIT_FORM)
        } else {
            companyId = this.getCcCompanyId(value);

            if (this.props.config && this.props.config.creditcard_schemes) {
                if (!Validator.isCompanyIdSupported('', {config: this.props.config, id: companyId})) {
                    this.props.setFormFieldErrorMsg({
                        ...errors,
                        [CreditFormFields.CARD_NUMBER]: `${PageElementType.CREDIT_FORM}.not_supported_card_type`
                    }, PageElementType.CREDIT_FORM)
                }
            }

            this.setState({
                ccCompanyId: this.getCcCompanyId(value),
                ccSide: CcSide.FRONT
            });
            if (field) {
                field.focus();
            }
        }
    }

    // return true when card data is at least one field has errors
    hasCardDataErrors(): boolean {
        let errors: CreditFormStructure = this.props.formsErrors[PageElementType.CREDIT_FORM];
        return !!(errors[CreditFormFields.CARD_NUMBER] || errors[CreditFormFields.EXP_DATE] || errors[CreditFormFields.CVC]);
    }

    // Due to handle grouping with cursor position,
    // this function has to be executed on keyUp (on keyDown cursor position will be broken)
    handleOnKeyUp(event: FormEvent) {
        let input = event.target as HTMLInputElement;
        let name = input.name as CreditFormFields;
        let value = input.value;
        let nativeEvent = event.nativeEvent as KeyboardEvent;

        // when Backspace, go to previous field
        if (nativeEvent.key === 'Backspace' && value === '') {
            switch (name) {
                case CreditFormFields.CVC:
                    this.refExpDate.current?.focus();
                    break;
                case CreditFormFields.EXP_DATE:
                    this.refCardNumber.current?.focus();
                    break;
            }
        }
    }

    getEncryptedData(): CreditCardEncryptedData | null {
        let form = this.props.forms[PageElementType.CREDIT_FORM];
        if (form) {
            // @ts-ignore
            return window.getEncryptedData(
                {
                    cardNumber: form[CreditFormFields.CARD_NUMBER],
                    cardOwner: form[CreditFormFields.OWNER],
                    cardCvc: form[CreditFormFields.CVC],
                    cardExpiryMonth: form[CreditFormFields.EXP_DATE].split('/')[0],
                    cardExpiryYear: form[CreditFormFields.EXP_DATE].split('/')[1],
                    cardExpiry: form[CreditFormFields.EXP_DATE]
                }
            );
        } else {
            return null;
        }
    }

    getCcCompanyName(): string {
        let recognizedCreditCard: CreditCardType[] = CREDIT_CARD_TYPES.filter((e: CreditCardType) => e.id === this.state.ccCompanyId);
        if (recognizedCreditCard.length === 0) {
            console.warn('Credit card type was not recognized');
            return "-1";
        } else {
            return recognizedCreditCard[0].name;
        }
    }

    getCcFormErrorMsg(): string {
        let generalErrorMsgKey = `${PageElementType.CREDIT_FORM}.invalid_card_data`;
        let notSupportedCcTypeMsgKey = `${PageElementType.CREDIT_FORM}.not_supported_card_type`;
        let currentErrorMsgKey = this.props.formsErrors[PageElementType.CREDIT_FORM][CreditFormFields.CARD_NUMBER];
        let errorMsgKey = generalErrorMsgKey;

        if (currentErrorMsgKey === notSupportedCcTypeMsgKey) {
            errorMsgKey = notSupportedCcTypeMsgKey
        }

        return this.props.getTranslation(errorMsgKey);
    }

    isAmex(): boolean {
        // id form credit-card-types.js
        return this.state.ccCompanyId === '1';
    }

    calculateCvcLength() {
        return this.isAmex() ? 4 : 3;
    }
}
