import {CreditFormFields, DebitFormFields, LanguageSymbol, PageElementType} from "../utils/types";

export const translations = {
    [LanguageSymbol.DE]: {
        mandatory_field: 'Das ist ein Pflichtfeld',

        [PageElementType.DEBIT_FORM]: {
            [DebitFormFields.OWNER]: 'Kontoinhaber',
            [DebitFormFields.IBAN]: 'IBAN',
            invalid_owner: 'Bitte geben Sie einen korrekten Kontoinhaber an',
            invalid_iban: 'Bitte geben Sie eine korrekte IBAN an'
        },

        [PageElementType.CREDIT_FORM]: {
            [CreditFormFields.OWNER]: 'Karteninhaber',
            [CreditFormFields.CARD_NUMBER]: 'Kartennnummer',
            [CreditFormFields.EXP_DATE]: 'Gültig bis',
            [CreditFormFields.CVC]: 'CVC',
            card_data: 'Kartendaten',
            exp_date_placeholder: 'MM / JJ',
            invalid_owner: 'Bitte geben Sie einen korrekten Karteninhaber an',
            invalid_card_number: 'Das ist keine gültige Kartennummer',
            not_supported_card_type: 'Dieser Kreditkartentyp wird vom Händler nicht unterstützt',
            invalid_exp_date: 'Ungültiges Ablaufdatum',
            invalid_cvc: 'Ungültiger CVC',
            invalid_card_data: 'Bitte geben Sie die korrekten Kartendaten an'
        },

        [PageElementType.DYNAMIC_FORM]: {
            mandatory_field: 'Das ist ein Pflichtfeld',
            invalid_data: 'Bitte geben Sie gültige Daten ein'
        }
    },


    [LanguageSymbol.EN]: {
        mandatory_field: 'This field is mandatory',

        [PageElementType.DEBIT_FORM]: {
            [DebitFormFields.OWNER]: 'Account owner',
            [DebitFormFields.IBAN]: 'IBAN',
            invalid_owner: 'Please enter a valid account owner',
            invalid_iban: 'Please enter a valid IBAN',
        },

        [PageElementType.CREDIT_FORM]: {
            [CreditFormFields.OWNER]: 'Card holder',
            [CreditFormFields.CARD_NUMBER]: 'Card number',
            [CreditFormFields.EXP_DATE]: 'Valid thru',
            [CreditFormFields.CVC]: 'CVC',
            card_data: 'Card data',
            exp_date_placeholder: 'MM / YY',
            invalid_owner: 'Please enter a valid card holder',
            invalid_card_number: 'This is not a valid card number',
            not_supported_card_type: 'This type of credit card is not supported by the merchant',
            invalid_exp_date: 'Invalid expiration date',
            invalid_cvc: 'Invalid CVC',
            invalid_card_data: 'Please enter valid card data'
        },

        [PageElementType.DYNAMIC_FORM]: {
            mandatory_field: 'This field is mandatory',
            invalid_data: 'Please enter a valid data'
        }
    }
}
